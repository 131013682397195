import { Box } from 'grommet';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapContextProvider } from './service/map/MapContext';
import { AgoroMap } from './components/Map/AgoroMap';
import './Map.style.css';
import { createJourneyAction, getJourneyByIdAction, getProducerByIdAction } from '../../redux/actions/producer-action';

export const MapV2Page = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { journeyId } = useParams();
  const { upsellingBoundaryTool, cpBoundaryTool } = useFlags();
  const [showUpsellingBanner, setShowUpsellingBanner] = useState<boolean>(false);
  const user = useSelector((state: any) => {
    return state.user.userData;
  });
  const salesforceId = useSelector((state: any) => {
    return state.producer.journeyInformation?.opportunityId ?? state.producer.journeyInformation?.leadId;
  });
  const producer = useSelector((state: any) => {
    return state.producer.producerInformation;
  });

  useEffect(() => {
    setShowUpsellingBanner(
      upsellingBoundaryTool &&
        producer?.caseStatus === 'Document & Data Collection' &&
        !!producer?.caseStatusHistory?.find((i) => i.status === 'In Support') &&
        user?.country === 'US',
    );
  }, [upsellingBoundaryTool, producer, salesforceId]);

  useEffect(() => {
    if (journeyId?.length === 12) {
      dispatch(getJourneyByIdAction(journeyId));
    }
  }, []);

  useEffect(() => {
    if (salesforceId || journeyId?.length !== 12) {
      dispatch(getProducerByIdAction(salesforceId ?? journeyId));
    }
  }, [salesforceId]);

  useEffect(() => {
    if (producer && !salesforceId) {
      dispatch(createJourneyAction(producer.leadId, producer.opportunityId));
    }
  }, [producer]);

  const isChannelPartner = user?.channelPartnerId;
  if (isChannelPartner && !cpBoundaryTool) {
    navigate(-1);
  }

  return (
    <Box className={'agoro-map-wrapper'} style={{ height: '100%' }}>
      {showUpsellingBanner && (
        <Box
          style={{
            width: '100%',
            height: '68px',
            backgroundColor: '#D03450',
            color: '#fff',
            fontFamily: 'Avenir',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: 900,
            lineHeight: '20px',
            letterSpacing: '1.12px',
            justifyContent: 'center',
            // position: 'absolute'
          }}
        >
          You are on the Upselling flow. Every change made will be considered an addition to all previously inputted information. To edit past data
          collection inputs, please get in touch with the Product Team.
        </Box>
      )}
      <Box
        style={{
          height: '100%',
          width: '100%',
          position: 'relative',
        }}
      >
        <MapContextProvider>
          <AgoroMap />
        </MapContextProvider>
      </Box>
    </Box>
  );
};
