import { ExtendedFeature, geoMercator, geoPath, GeoPermissibleObjects } from 'd3-geo';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import warningIconRed from '../assets/warning-icon-red.svg';
import { Box } from 'grommet';

export function isClockWise(coords: Array<Array<number>>) {
  let sum = 0;
  for (let i = 0; i < coords?.length; i++) {
    sum += coords[i][0] * (coords[i + 1] || coords[0])[1] - (coords[i + 1] || coords[0])[0] * coords[i][1];
  }
  return sum >= 0;
}

const getThumbnailCss = (potential: string | undefined, unenrolled: boolean | undefined) => {
  let fillColor = '#7ACF89';
  let backgroundColor = 'rgba(122, 207, 137, 0.2)';
  switch (potential) {
    case 'low':
      fillColor = '#C97C7D';
      backgroundColor = '#EBCDCE';
      break;
    case 'high':
      fillColor = '#A2CC76';
      backgroundColor = '#DCECCB';
      break;
    case 'medium':
      fillColor = '#F2B535';
      backgroundColor = '#FAE3B2';
      break;
    default:
      break;
  }

  if (unenrolled) {
    fillColor = '#808080';
    backgroundColor = 'rgba(128,128,128,0.2)';
  }

  return `
    fill: ${fillColor};
    align-content: 'center';
    text-align: 'center';
    stroke: ${fillColor};
    background-color: ${backgroundColor};
    padding: '0.4rem';
  `;
};

const Thumbnail = styled.svg<ISvg>`
  stroke-width: 1px;
  border-radius: 4px;

  ${({ fieldPotential, unenrolled }) => getThumbnailCss(fieldPotential, unenrolled)}
`;

type Width = number;
type Height = number;

interface ISvg {
  height: Height;
  width: Width;
  active: boolean;
  activeCustom?: boolean;
  marginLeft?: string;
  fieldPotential?: string;
  unenrolled?: boolean;
}

interface FieldThumbnailProps {
  feature: any | null | undefined;
  height: Height;
  width: Width;
  active?: boolean;
  activeCustom?: boolean;
  marginLeftFieldThumbnail?: string;
  fieldPotential?: string;
  unenrolled?: boolean;
}

export const FieldThumbnail = ({
  feature,
  height,
  width,
  active = false,
  activeCustom = true,
  marginLeftFieldThumbnail,
  fieldPotential,
  unenrolled = false,
}: FieldThumbnailProps): JSX.Element => {
  const clockWise = isClockWise(feature?.geometry?.coordinates[0]);
  if (feature?.geometry?.coordinates && feature?.geometry?.coordinates?.length && feature?.geometry?.coordinates?.length === 1 && clockWise) {
    feature.geometry.coordinates[0].reverse();
  }
  const d = useMemo(() => {
    const geoObject = feature as GeoPermissibleObjects;
    const projection = geoMercator();
    const geoGenerator = geoPath().projection(projection);
    projection.fitExtent(
      [
        [0, 0],
        [width - 12.8, height - 12.8],
      ],
      geoObject as ExtendedFeature,
    );
    return geoGenerator(geoObject) || undefined;
  }, [feature, height, width]);

  return feature.properties.intersects ? (
    <Box
      style={{
        height: height,
        width: width,
        backgroundColor: '#d0345033',
        borderRadius: '4px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={warningIconRed} height={24} width={24} alt='Warning icon' />
    </Box>
  ) : (
    <Thumbnail
      height={height - 12.8}
      width={width - 12.8}
      active={active}
      activeCustom={activeCustom}
      marginLeft={marginLeftFieldThumbnail}
      fieldPotential={fieldPotential}
      unenrolled={unenrolled}
    >
      <path d={d} />
    </Thumbnail>
  );
};
