import { Box, TextInput as TextInputGroomet, Text, CheckBox, Image, RadioButton } from 'grommet';
import herdMarker from '../../../../assets/BoundaryTool/infoIcons/herd.svg';
import deleteIcon from '../../../../assets/BoundaryTool/deleteIcon.svg';
import copyIcon from '../../../../assets/BoundaryTool/copy-icon.svg';
import infoIcon from '../../../../assets/BoundaryTool/info-icon.svg';
import warningIcon from '../../../../assets/BoundaryTool/warning.svg';
import mapboxgl from 'mapbox-gl';
import { Select } from '../Input/Select';
import { TextInput } from '../Input/TextInput';
import { DateInput } from '../Input/DateInput';
import { Feature } from '@turf/turf';
import { monthList } from '../../service/common/constants';
import { addDays, formatDate } from '../../service/common/utils';
import { DateRange } from '../Input/DateRange';
import { LocationInput } from '../Input/LocationInput';
import Field from '../../types/Field';
import { FieldLayout } from '../../types/FieldLayout';
import { useEffect, useRef, useState } from 'react';
import { states, useMapContext } from '../../service/map/MapContext';
import { useParams } from 'react-router-dom';
import { StyledTooltip } from '../common/StyledTooltip';
import { EditData } from '../../types/EditChanges';
import { Trans, useTranslation } from 'react-i18next';

export interface Herd {
  versionId: string;
  id: string | number;
  growerId: string;
  journeyId: string;
  fieldId?: string;
  fieldName?: string;
  paddockId?: string;
  paddockName?: string;
  name: string;
  operationType: string;
  animal: string;
  breed: string;
  startDate?: Date;
  endDate?: Date;
  isCurrent?: boolean;
  dateIn?: Date;
  data?: {
    totalSize?: number;
    avgWeight?: number;
    birthingMonth?: string;
    conceptionRate?: number;
    averageAge?: number;
    frameSize?: string;
  };
  marker?: mapboxgl.Marker;
  unsavedMoveEvent: any;
  changes: EditData;
}

interface HerdItemProps {
  state: states;
  herd: Herd;
  updateHerd: (herd: any) => void;
  removeHerd: (herd: any) => void;
  duplicateHerd: (herd: any) => void;
  getLastChangeDate: (herd: any) => Promise<{ lastDate: Date }>;
  fields: Field[];
  paddocks: { [key: string]: Feature[] };
  currentVersion: FieldLayout;
  simple?: boolean;
  editing?: boolean;
}

export const HerdItem = ({
  state,
  herd,
  updateHerd,
  removeHerd,
  duplicateHerd,
  getLastChangeDate,
  fields,
  paddocks,
  currentVersion,
  simple,
  editing,
}: HerdItemProps) => {
  const { t } = useTranslation();
  const { journeyId } = useParams();
  const { filterHerdMarkers, handleHerdMarkers } = useMapContext();
  const newHerd = typeof herd.id === 'number';
  const hasIdAndIsSecondSetup = !newHerd && state === 'SECOND_SETUP';
  const [isHerdCurrent, setIsHerdCurrent] = useState<boolean>(!herd.endDate);
  const [minEditDate, setMinEditDate] = useState<Date>();

  const paddock = herd.fieldId && paddocks[herd.fieldId]?.length === 1 ? paddocks[herd.fieldId][0] : undefined;
  const fieldsWithOffsite = useRef([
    {
      id: 'OFFSITE',
      properties: {
        name: 'OFFSITE',
      },
    },
    ...fields,
  ]);
  const [moveEvent, setMoveEvent] = useState<any>({
    growerId: journeyId ?? '',
    journeyId: journeyId,
    herdName: herd.name,
    herdId: herd.id,
    herdVersionId: herd.versionId,
    fieldId: herd.fieldId,
    fieldName: fieldsWithOffsite.current.find((field) => field.id === herd.fieldId)?.properties?.name,
    paddockId: paddock ? paddock.id : herd.paddockId,
    paddockName: paddock ? paddock.properties?.name : undefined,
  });

  useEffect(() => {
    if (!editing) return;

    getLastChangeDate(herd).then(({ lastDate }) => {
      setMinEditDate(addDays(lastDate, 1));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [herd, editing]);

  useEffect(() => {
    if (herd.paddockId) {
      filterHerdMarkers(handleHerdMarkers(herd));
    } else {
      herd.marker?.remove();
    }
  }, [herd]);

  function getMinDateIn(): Date {
    return new Date(
      Math.max(...([currentVersion.startDate, herd.startDate].filter((date) => !!date) as Date[]).map((date) => new Date(date).getTime())),
    );
  }

  function getMinDateChanges(): Date {
    return new Date(Math.max(...([getMinDateIn(), minEditDate].filter((date) => !!date) as Date[]).map((date) => new Date(date).getTime())));
  }

  function getMaxDateIn(): Date {
    return new Date(
      Math.min(
        ...(
          [currentVersion.endDate, herd.endDate ? addDays(new Date(herd.endDate), -1) : undefined, new Date()].filter((date) => !!date) as Date[]
        ).map((date) => new Date(date).getTime()),
      ),
    );
  }

  const herdOperations = ['Cow Calf', 'Feeder Steers'];
  const animalTypes = ['Cattle', 'Sheep', 'Goats'];
  const breeds = ['Black Angus', 'Red Angus', 'Hereford', 'Simmental', 'Brichiaria Brizanta', 'Angus Cross', 'Nelore', 'Brahman', 'Brangus'];
  const frameSize = ['Small', 'Medium', 'Large'];

  return (
    <Box
      key={herd.id.toString()}
      height={{ min: 'fit-content' }}
      pad={'0 0 1.5rem 0'}
      border={!simple ? { side: 'bottom', size: '1px', color: '#494949' } : undefined}
    >
      {newHerd && (
        <Box direction={'row'} align={'center'}>
          <img src={herdMarker} height={36} width={36} alt='Active indicator' />

          <Box width={'144px'} margin={{ left: '8px', right: '11px' }}>
            <TextInputGroomet
              plain
              focusIndicator={false}
              placeholder={'Herd name'}
              value={herd.name}
              style={{ height: '36px', border: '1px solid #282828' }}
              onChange={(e) => {
                herd.name = e.target.value;
                updateHerd(herd);
              }}
            />
          </Box>

          <StyledTooltip label={t('fieldFlow.herdItem.deleteTip')} key={'delete-tooltip'}>
            <img
              src={deleteIcon}
              height={24}
              width={24}
              alt='Delete Icon'
              style={{
                cursor: 'pointer',
              }}
              onClick={(event) => {
                event.stopPropagation();
                removeHerd(herd);
              }}
            />
          </StyledTooltip>
          <StyledTooltip label={t('fieldFlow.herdItem.duplicateTip')} key={'duplicate-tooltip'}>
            <img
              src={copyIcon}
              height={24}
              width={24}
              alt='Copy Icon'
              style={{
                cursor: 'pointer',
              }}
              onClick={(event) => {
                event.stopPropagation();
                duplicateHerd(herd);
              }}
            />
          </StyledTooltip>
        </Box>
      )}

      <Text size={'16px'} style={{ lineHeight: '20px' }} color={'#1457A8'} weight={700} margin={{ top: state === 'SECOND_SETUP' ? '0' : '16px' }}>
        {t('fieldFlow.herdItem.herdInfo.title')}
      </Text>

      {hasIdAndIsSecondSetup && (
        <Box direction={'row'} align={'center'} margin={{ top: '8px' }} gap={'10px'}>
          <img src={herdMarker} height={36} width={36} alt='Active indicator' />

          <Text size={'20px'} style={{ lineHeight: '28px' }} color={'#282828'} weight={700}>
            {herd.name}
          </Text>
        </Box>
      )}

      <Box direction={'row'} justify={'between'} margin={{ top: '16px' }} gap={'1rem'}>
        <TextInput
          width={'33%'}
          disabled={hasIdAndIsSecondSetup}
          label={t('fieldFlow.herdItem.herdInfo.operationType.label')}
          type={'text'}
          onlyChars={true}
          value={
            editing && herd.changes?.newData && Object.prototype.hasOwnProperty.call(herd.changes.newData, 'operationType')
              ? herd.changes.newData?.operationType
              : herd.operationType ?? ''
          }
          suggestions={herdOperations
            .map((operation) => t(`fieldFlow.herdItem.herdInfo.operationType.${operation}`))
            .sort((a, b) => a.localeCompare(b))}
          onChange={(operation) => {
            if (!editing) {
              herd.operationType = operation;
              updateHerd(herd);
            } else {
              const newData = { ...herd.changes?.newData, operationType: operation };
              herd.changes = { ...herd.changes, newData };
              updateHerd(herd);
            }
          }}
        />

        <TextInput
          width={'33%'}
          disabled={hasIdAndIsSecondSetup}
          label={t('fieldFlow.herdItem.herdInfo.animalType.label')}
          type={'text'}
          onlyChars={true}
          value={
            editing && herd.changes?.newData && Object.prototype.hasOwnProperty.call(herd.changes.newData, 'animal')
              ? herd.changes.newData?.animal
              : herd.animal ?? ''
          }
          suggestions={animalTypes.map((animal) => t(`fieldFlow.herdItem.herdInfo.animalType.${animal}`)).sort((a, b) => a.localeCompare(b))}
          onChange={(animal) => {
            if (!editing) {
              herd.animal = animal;
              updateHerd(herd);
            } else {
              const newData = { ...herd.changes?.newData, animal: animal };
              herd.changes = { ...herd.changes, newData };
              updateHerd(herd);
            }
          }}
        />

        <TextInput
          width={'33%'}
          disabled={hasIdAndIsSecondSetup}
          label={t('fieldFlow.herdItem.herdInfo.breed.label')}
          type={'text'}
          onlyChars={true}
          value={
            editing && herd.changes?.newData && Object.prototype.hasOwnProperty.call(herd.changes.newData, 'breed')
              ? herd.changes.newData.breed
              : herd.breed ?? ''
          }
          suggestions={breeds.map((breed) => t(`fieldFlow.herdItem.herdInfo.breed.${breed}`)).sort((a, b) => a.localeCompare(b))}
          onChange={(breed) => {
            if (!editing) {
              herd.breed = breed;
              updateHerd(herd);
            } else {
              const newData = { ...herd.changes?.newData, breed: breed };
              herd.changes = { ...herd.changes, newData };
              updateHerd(herd);
            }
          }}
        />
      </Box>

      <Box direction={'row'} margin={{ top: '16px' }} gap={'1rem'}>
        <TextInput
          width={'33%'}
          disabled={hasIdAndIsSecondSetup}
          label={t('fieldFlow.herdItem.herdInfo.herdSize')}
          type={'number'}
          onlyPositive={true}
          onlyIntegers={true}
          value={
            editing && herd.changes?.newData?.data && Object.prototype.hasOwnProperty.call(herd.changes.newData.data, 'totalSize')
              ? herd.changes.newData.data.totalSize.toString()
              : herd.data?.totalSize?.toString() ?? ''
          }
          onChange={(totalSize) => {
            if (!editing) {
              herd.data = herd.data || {};
              herd.data.totalSize = Number(totalSize);
              updateHerd(herd);
            } else {
              const data = { ...herd.changes?.newData?.data, totalSize: Number(totalSize) };
              const newData = { ...herd.changes?.newData, data };
              herd.changes = { ...herd.changes, newData };
              updateHerd(herd);
            }
          }}
        />

        <Select
          width={'33%'}
          disabled={hasIdAndIsSecondSetup}
          label={t('fieldFlow.herdItem.herdInfo.frameSize.label')}
          value={
            editing && herd.changes?.newData?.data && Object.prototype.hasOwnProperty.call(herd.changes.newData.data, 'frameSize')
              ? herd.changes.newData.data.frameSize
              : herd.data?.frameSize ?? ''
          }
          options={frameSize.map<string>((size) => t(`fieldFlow.herdItem.herdInfo.frameSize.${size}`))}
          onChange={(frameSize) => {
            if (!editing) {
              herd.data = herd.data || {};
              herd.data.frameSize = frameSize;
              updateHerd(herd);
            } else {
              const data = { ...herd.changes?.newData?.data, frameSize };
              const newData = { ...herd.changes?.newData, data };
              herd.changes = { ...herd.changes, newData };
              updateHerd(herd);
            }
          }}
        />

        <TextInput
          width={'33%'}
          disabled={hasIdAndIsSecondSetup}
          label={t('fieldFlow.herdItem.herdInfo.avgWeight')}
          type={'number'}
          onlyPositive={true}
          onlyIntegers={true}
          value={
            editing && herd.changes?.newData?.data && Object.prototype.hasOwnProperty.call(herd.changes.newData.data, 'avgWeight')
              ? herd.changes.newData.data.avgWeight
              : herd.data?.avgWeight?.toString() ?? ''
          }
          onChange={(weight) => {
            if (!editing) {
              herd.data = herd.data || {};
              herd.data.avgWeight = Number(weight);
              updateHerd(herd);
            } else {
              const data = { ...herd.changes?.newData?.data, avgWeight: Number(weight) };
              const newData = { ...herd.changes?.newData, data };
              herd.changes = { ...herd.changes, newData };
              updateHerd(herd);
            }
          }}
        />
      </Box>

      <Box direction={'row'} margin={{ top: '16px' }} gap={'1rem'}>
        <Select
          width={'33%'}
          disabled={hasIdAndIsSecondSetup}
          label={t('fieldFlow.herdItem.herdInfo.birthingMonth')}
          value={
            editing && herd.changes?.newData?.data && Object.prototype.hasOwnProperty.call(herd.changes.newData.data, 'birthingMonth')
              ? herd.changes.newData.data.birthingMonth
              : herd.data?.birthingMonth ?? ''
          }
          options={monthList()}
          onChange={(month) => {
            if (!editing) {
              herd.data = herd.data || {};
              herd.data.birthingMonth = month;
              updateHerd(herd);
            } else {
              const data = { ...herd.changes?.newData?.data, birthingMonth: month };
              const newData = { ...herd.changes?.newData, data };
              herd.changes = { ...herd.changes, newData };
              updateHerd(herd);
            }
          }}
        />

        <TextInput
          width={'33%'}
          disabled={hasIdAndIsSecondSetup}
          label={t('fieldFlow.herdItem.herdInfo.conceptionRate')}
          type={'number'}
          onlyPositive={true}
          onlyIntegers={true}
          value={
            editing && herd.changes?.newData?.data && Object.prototype.hasOwnProperty.call(herd.changes.newData.data, 'conceptionRate')
              ? herd.changes.newData.data.conceptionRate
              : herd.data?.conceptionRate?.toString() ?? ''
          }
          onChange={(conceptionRate) => {
            if (!editing) {
              herd.data = herd.data || {};
              herd.data.conceptionRate = Number(conceptionRate);
              updateHerd(herd);
            } else {
              const data = { ...herd.changes?.newData?.data, conceptionRate: Number(conceptionRate) };
              const newData = { ...herd.changes?.newData, data };
              herd.changes = { ...herd.changes, newData };
              updateHerd(herd);
            }
          }}
        />

        <TextInput
          width={'33%'}
          disabled={hasIdAndIsSecondSetup}
          label={t('fieldFlow.herdItem.herdInfo.averageAge')}
          type={'number'}
          onlyPositive={true}
          onlyIntegers={true}
          value={
            editing && herd.changes?.newData?.data && Object.prototype.hasOwnProperty.call(herd.changes.newData.data, 'averageAge')
              ? herd.changes.newData.data.averageAge
              : herd.data?.averageAge?.toString() ?? ''
          }
          onChange={(averageAge) => {
            if (!editing) {
              herd.data = herd.data || {};
              herd.data.averageAge = Number(averageAge);
              updateHerd(herd);
            } else {
              const data = { ...herd.changes?.newData?.data, averageAge: Number(averageAge) };
              const newData = { ...herd.changes?.newData, data };
              herd.changes = { ...herd.changes, newData };
              updateHerd(herd);
            }
          }}
        />
      </Box>

      {/*herd creation*/}
      {!editing && (
        <>
          <Box direction={'row'} margin={{ top: '16px' }} gap={'1rem'} width={'100%'}>
            <DateRange
              disabled={hasIdAndIsSecondSetup}
              disabledEndDate={isHerdCurrent}
              startDate={herd.startDate}
              endDate={herd.endDate}
              minStartDate={undefined}
              maxStartDate={currentVersion.endDate ?? new Date()}
              minEndDate={currentVersion.startDate}
              maxEndDate={undefined}
              startLabel={t('fieldFlow.herdItem.herdInfo.added')}
              endLabel={t('fieldFlow.herdItem.herdInfo.removed')}
              onChange={(dates) => {
                if (herd.dateIn && dates?.startDate && herd.dateIn < dates.startDate) {
                  herd.dateIn = dates.startDate;
                } else if (herd.dateIn && dates?.endDate && herd.dateIn > dates.endDate) {
                  herd.dateIn = dates.endDate;
                }
                herd = { ...herd, ...dates };
                updateHerd(herd);
              }}
            />

            {!hasIdAndIsSecondSetup && (
              <Box margin={{ top: '28.5px' }}>
                <CheckBox
                  toggle
                  checked={isHerdCurrent}
                  label={t('fieldFlow.dateRangeModal.current')}
                  onChange={(event) => {
                    setIsHerdCurrent(event.target.checked);
                    herd.isCurrent = event.target.checked;
                    if (event.target.checked) {
                      herd.endDate = undefined;
                    }
                    updateHerd(herd);
                  }}
                />
              </Box>
            )}
          </Box>

          <Text size={'16px'} style={{ lineHeight: '20px' }} color={'#1457A8'} weight={700} margin={{ top: '32px' }}>
            {t('fieldFlow.herdItem.locationInfo.title')}
            <Text margin={{ left: '3px' }} weight={400} color={'#282828'}>
              {t('fieldFlow.from')} {formatDate(currentVersion.startDate)} {t('fieldFlow.to')}{' '}
              {currentVersion.endDate ? formatDate(currentVersion.endDate) : t('fieldFlow.today')}
            </Text>
          </Text>

          {!simple && (
            <Text size={'16px'} style={{ lineHeight: '20px' }} color={'#282828'} weight={400} margin={{ top: '8px' }}>
              <Trans i18nKey={'fieldFlow.herdItem.locationInfo.message'} values={{ name: currentVersion.name }} />
            </Text>
          )}

          <Box direction={'row'} margin={{ top: '8px' }} gap={'3.2%'}>
            <DateInput
              width={'31.2%'}
              label={t('fieldFlow.herdItem.locationInfo.dateIn')}
              value={herd?.dateIn ?? undefined}
              min={getMinDateIn()}
              max={getMaxDateIn()}
              onBlur={() => {
                const maxDate = getMaxDateIn();
                const minDate = getMinDateIn();
                if (!!herd?.dateIn && herd?.dateIn < new Date(minDate)) herd.dateIn = minDate;
                if (!!herd?.dateIn && maxDate && herd?.dateIn > new Date(maxDate)) herd.dateIn = maxDate;
                updateHerd(herd);
              }}
              onChange={(dateIn) => {
                herd.dateIn = dateIn;
                if (hasIdAndIsSecondSetup) {
                  herd.unsavedMoveEvent = { ...moveEvent, dateIn };
                  setMoveEvent(herd.unsavedMoveEvent);
                }
                updateHerd(herd);
              }}
            />

            <LocationInput
              width={'31.2%'}
              label={t('fieldFlow.field')}
              value={
                hasIdAndIsSecondSetup
                  ? fieldsWithOffsite.current.find((field) => field.id === herd.fieldId)
                  : fields.find((field) => field.id === herd.fieldId)
              }
              options={hasIdAndIsSecondSetup ? fieldsWithOffsite.current : fields}
              labelKey={(field) => field.properties.name}
              onChange={(field) => {
                herd.paddockId = undefined;
                herd.fieldId = field.id;
                herd.fieldName = field.properties?.name;
                if (field.id === 'OFFSITE') herd.paddockId = 'OFFSITE';
                if (paddocks[field.id]?.length === 1) {
                  herd.paddockId = paddocks[field.id][0].id?.toString();
                  herd.paddockName = paddocks[field.id][0].properties?.name?.toString();
                }
                if (hasIdAndIsSecondSetup) {
                  herd.unsavedMoveEvent = {
                    ...moveEvent,
                    paddockId: herd.paddockId,
                    paddockName: paddocks[field.id]?.[0]?.properties?.name ?? 'OFFSITE',
                    fieldId: herd.fieldId,
                    fieldName: field.properties?.name,
                  };
                  setMoveEvent(herd.unsavedMoveEvent);
                }
                updateHerd(herd);
              }}
            />

            {herd.fieldId && paddocks[herd.fieldId]?.length > 1 && (
              <LocationInput
                width={'31.2%'}
                label={t('fieldFlow.herdItem.locationInfo.paddock')}
                value={herd.paddockId ? paddocks[herd.fieldId]?.find((paddock) => paddock.id === herd.paddockId) : {}} //Value doesn't get cleared if options change but labelKey values are the same
                options={paddocks[herd.fieldId!]}
                labelKey={(paddock: any) => paddock.properties.name}
                onChange={(paddock: any) => {
                  herd.paddockId = paddock.id;
                  herd.paddockName = paddock.properties?.name;
                  if (hasIdAndIsSecondSetup) {
                    herd.unsavedMoveEvent = {
                      ...moveEvent,
                      paddockId: paddock.id,
                      paddockName: paddock.properties?.name,
                    };
                    setMoveEvent(herd.unsavedMoveEvent);
                  }
                  updateHerd(herd);
                }}
              />
            )}
          </Box>
        </>
      )}

      {/*herd edition*/}
      {editing && (
        <>
          <Box direction={'row'} align={'center'} margin={{ top: '32px' }} gap={'6px'}>
            <Text size={'16px'} style={{ lineHeight: '20px' }} color={'#1457A8'} weight={700}>
              {t('fieldFlow.herdItem.locationInfo.edit.title')}
            </Text>
            <StyledTooltip label={t('fieldFlow.herdItem.locationInfo.edit.message')}>
              <Image height={24} width={24} src={infoIcon} />
            </StyledTooltip>
          </Box>

          <Box direction={'row'} margin={{ top: '8px' }} gap={'3.2%'} align={'baseline'}>
            <Box width={'31.2%'} direction={'row'}>
              <RadioButton
                name={'hg'}
                checked={herd.changes?.reason === 'WRONG_INPUT'}
                label={t('fieldFlow.herdItem.locationInfo.edit.wrongInput')}
                onChange={() => {
                  herd.changes = { ...herd.changes, reason: 'WRONG_INPUT', date: new Date() };
                  updateHerd(herd);
                }}
              />
              <StyledTooltip label={t('fieldFlow.herdItem.locationInfo.edit.message2')}>
                <Image height={24} width={24} src={warningIcon} />
              </StyledTooltip>
            </Box>
            <Box width={'31.2%'}>
              <RadioButton
                name={'hg'}
                checked={herd.changes?.reason === 'CHANGES'}
                label={t('fieldFlow.herdItem.locationInfo.edit.changes')}
                onChange={() => {
                  const minDate = getMinDateChanges();
                  const maxDate = getMaxDateIn();
                  herd.changes = { ...herd.changes, reason: 'CHANGES', date: minDate > maxDate ? undefined : maxDate };
                  updateHerd(herd);
                }}
              />
            </Box>
            {herd.changes?.reason === 'CHANGES' && (
              <DateInput
                width={'31.2%'}
                label={t('fieldFlow.herdItem.locationInfo.edit.date')}
                value={herd.changes?.date}
                min={getMinDateChanges()}
                max={getMaxDateIn()}
                onChange={(editDate) => {
                  herd.changes = { ...herd.changes, date: editDate };
                  updateHerd(herd);
                }}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
