import { Box, Button, Layer, Spinner, Text } from 'grommet';
import { Select } from '../Input/Select';
import { TextInput } from '../Input/TextInput';
import { DateInput } from '../Input/DateInput';
import { useMapContext } from '../../service/map/MapContext';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LocationInput } from '../Input/LocationInput';
import { addDays } from '../../service/common/utils';
import { useTranslation } from 'react-i18next';

interface MoveEvent {
  growerId: string;
  journeyId: string;
  herdName: string;
  herdId: string;
  herdVersionId: string;
  dateIn?: Date;
  paddockId?: string;
  paddockName?: string;
  fieldId?: string;
  fieldName?: string;
  remainingForage?: number;
}

export const MoveEventModal = ({ herd, onCancel }: { herd: any; onCancel: () => void }) => {
  const { t } = useTranslation();
  const { journey, setState, fields, paddocks, herds, saveMoveEvent, getMoveLocations, currentVersion } = useMapContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fieldId = searchParams.get('fieldId');
  const { journeyId } = useParams();
  const latestRotation = herd.rotation[0];

  const [loading, setLoading] = useState<boolean>(false);
  const [locationDictionary, setLocationDictionary] = useState<any>();
  const [moveLocation, setMoveLocation] = useState<string>('');
  const [moveEvent, setMoveEvent] = useState<MoveEvent>({
    growerId: journey?.leadId ?? journey?.opportunityId ?? '',
    journeyId: journey?.id ?? '',
    herdName: herd.name,
    herdId: herd.id,
    herdVersionId: herd.versionId,
    remainingForage: 0,
  });

  function getLocationName(rotation: any) {
    if (rotation.data?.fieldId === 'OFFSITE') return rotation.data?.fieldName;

    if (!rotation.data?.paddockName) return undefined;

    if (rotation.data?.fieldName === rotation.data?.paddockName) {
      return rotation.data?.paddockName;
    }
    return `${rotation.data.fieldName} - ${rotation.data.paddockName}`;
  }

  useEffect(() => {
    load();

    async function load() {
      const locations = getMoveLocations(latestRotation?.data?.paddockId ? [latestRotation.data.paddockId] : [latestRotation.fieldId]);
      setLocationDictionary(locations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, paddocks]);

  function isButtonDisabled() {
    if (moveEvent.fieldId === 'OFFSITE') return !moveEvent.remainingForage || !moveEvent.dateIn;
    return moveEvent.remainingForage == null || !moveEvent.paddockId || !moveEvent.dateIn;
  }

  function getMaxDate() {
    return new Date(
      Math.min(
        ...[currentVersion.endDate, herd.endDate ? addDays(new Date(herd.endDate), -1) : undefined, new Date()]
          .filter((date) => !!date)
          .map((date) => new Date(date!).getTime()),
      ),
    );
  }

  return (
    <Layer position='center' style={{ borderRadius: '12px' }}>
      {locationDictionary ? (
        <Box pad='24px' width='500px'>
          <Text size='26px' color='#282828' weight={800} style={{ lineHeight: '28px' }}>
            {t('fieldFlow.herdItem.herdInfo.moveEvent.title')}{' '}
          </Text>
          <Text margin={{ top: '8px', bottom: '12px' }} color='#494949' weight={400} style={{ lineHeight: '21px' }}>
            {t('fieldFlow.herdItem.herdInfo.moveEvent.message')}{' '}
          </Text>
          <Box direction={'row'} margin={{ bottom: '16px' }} justify={'between'}>
            <Select
              titleWeight={600}
              width={'214px'}
              label={t('fieldFlow.herdItem.herdInfo.moveEvent.movedLable')}
              disabled={true}
              handleEvent={true}
              onChange={(event) =>
                setMoveEvent((moveEvent) => {
                  const herd = herds[event.selected];
                  return {
                    ...moveEvent,
                    herdId: herd.id,
                    herdVersionId: herd.versionId,
                    herdName: herd.name,
                  };
                })
              }
              options={herds}
              labelKey={'name'}
              value={herd.name}
            />
            {latestRotation?.fieldId !== 'OFFSITE' && (
              <TextInput
                titleWeight={600}
                type={'number'}
                width={'214px'}
                label={t('fieldFlow.herdItem.herdInfo.moveEvent.remaining')}
                onChange={(value) =>
                  setMoveEvent((moveEvent) => {
                    const remainingForage = Math.round(parseFloat(value) * 100) / 100;
                    return { ...moveEvent, remainingForage };
                  })
                }
                value={moveEvent.remainingForage?.toString() ?? ''}
              />
            )}
          </Box>
          <Box direction={'row'} margin={{ bottom: '16px' }} justify={'between'}>
            <LocationInput
              titleWeight={600}
              width={'214px'}
              label={t('fieldFlow.herdItem.herdInfo.moveEvent.currentLocation')}
              disabled={!!latestRotation}
              onChange={() => {
                setMoveEvent((moveEvent) => {
                  return moveEvent;
                });
              }}
              value={getLocationName(latestRotation)}
              options={locationDictionary}
              labelKey={'locationName'}
            />
            <LocationInput
              titleWeight={600}
              width={'214px'}
              label={t('fieldFlow.herdItem.herdInfo.moveEvent.moveTo')}
              onChange={(location) => {
                setMoveLocation(location);
                setMoveEvent((moveEvent) => {
                  return {
                    ...moveEvent,
                    fieldName: location.fieldName,
                    paddockName: location.paddockName,
                    fieldId: location.fieldId,
                    paddockId: location.paddockId,
                  };
                });
              }}
              value={moveLocation}
              options={locationDictionary}
              labelKey={'locationName'}
            />
          </Box>
          <DateInput
            titleWeight={600}
            width={'214px'}
            label={t('fieldFlow.herdItem.herdInfo.moveEvent.date')}
            min={latestRotation ? addDays(new Date(latestRotation.data.dateIn), 1) : addDays(new Date(currentVersion.startDate), -1)}
            max={getMaxDate()}
            value={moveEvent.dateIn}
            onChange={(dateIn) =>
              setMoveEvent((moveEvent) => {
                return { ...moveEvent, dateIn };
              })
            }
          />
          <Box margin={{ top: '24px' }} justify={'between'} direction='row-responsive'>
            <Button
              plain
              focusIndicator={false}
              label={t('fieldFlow.cancel')}
              onClick={onCancel}
              style={{
                borderRadius: '0px',
                width: '220px',
                textAlign: 'center',
                border: '1px solid #282828',
                fontWeight: '700',
                fontSize: '16px',
                padding: '8px 60px',
                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
              }}
            />
            <Button
              focusIndicator={false}
              hoverIndicator={false}
              disabled={isButtonDisabled()}
              style={{
                width: '220px',
                borderRadius: '0px',
                fontWeight: '700',
                textAlign: 'center',
                fontSize: '16px',
                padding: '8px 60px',
                backgroundColor: '#FFE137',
                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
              }}
              label={
                loading ? (
                  <Box justify={'center'} align={'center'}>
                    <Spinner size={'small'} height={'16px'} width={'16px'} />
                  </Box>
                ) : (
                  t('fieldFlow.herdItem.herdInfo.moveEvent.create')
                )
              }
              onClick={async () => {
                setLoading(true);
                await saveMoveEvent(moveEvent, herd);
                if (fieldId !== moveEvent.fieldId && moveEvent.fieldId !== 'OFFSITE') {
                  setState('DETAILS');
                  navigate(`/v2/fields/${journeyId}?fieldId=${moveEvent.fieldId}`);
                }
                setLoading(false);
                onCancel();
              }}
              plain
              color={'#282828'}
            />
          </Box>
        </Box>
      ) : (
        <Box height={'340px'} width={'504px'} justify={'center'} align={'center'}>
          <Spinner alignSelf={'center'} size={'medium'} />
        </Box>
      )}
    </Layer>
  );
};

export default MoveEventModal;
