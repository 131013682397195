import { Box, Button, Image, Layer, RadioButtonGroup, Text, TextInput } from 'grommet';
import { Feature } from '@turf/helpers';
import deleteIcon from '../../../../assets/BoundaryTool/deleteIcon.svg';
import unenrollIcon from '../../../../assets/BoundaryTool/unenroll-icon.svg';
import { StyledTooltip } from '../common/StyledTooltip';
import { SyncErrors } from '../../service/FieldService';
import i18next, { TFunction } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import infoIcon from '../../../../assets/BoundaryTool/info-icon.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PracticeCheckbox from './PracticeCheckbox';
import { FieldThumbnail } from '../../../../components/FieldThumbnail';

export const FieldItem = React.memo(
  ({
    t,
    field,
    focusField,
    saveField,
    deleteField,
    changeFieldEnrollmentStatus,
    syncErrors,
    country,
    hidden = false,
    userRegion,
    upsellingBoundaryTool,
    producerData,
    practicesOnContract,
  }: {
    t: TFunction;
    field: Feature;
    focusField: (field: Feature) => void;
    saveField: (field: Feature) => void;
    deleteField: (field: Feature) => void;
    changeFieldEnrollmentStatus: (field: Feature, status: boolean, hidden?: boolean) => void;
    hidden?: boolean;
    syncErrors: SyncErrors;
    country: string;
    upsellingBoundaryTool: boolean;
    userRegion: string;
    producerData: any;
    practicesOnContract: any;
  }) => {
    // TODO: remove hideIclFields and control practices through contract
    const { brRowCropPractice, usStackTillageWithLegumeAddition, hideIclFields } = useFlags();
    const disablePracticeSelectionForRegularFlow =
      country === 'us' &&
      (!producerData?.caseStatusHistory?.find((i) => i.status === 'In Support') || !upsellingBoundaryTool) &&
      producerData?.StageName === 'Closed Won';
    const isUpsellingFlow = upsellingBoundaryTool && !!producerData?.caseStatusHistory?.find((i) => i.status === 'In Support');
    const hasICL = field.properties?.practices.indexOf('ICL') > -1;
    const [showUnenrollModal, setShowUnenrollModal] = useState<boolean>(false);
    const [practicesErrored, setPracticesErrored] = useState<boolean>(false);

    const hasPaymentError = !!syncErrors.paymentOptionErrors?.find((error: any) => error.id === field.id);
    const practiceTypes = [
      {
        label: (
          <Text size={'14px'} weight={400}>
            {t('fieldFlow.fieldItem.practiceType.options.rowCrop')}
          </Text>
        ),
        'data-cy': `practice-type-rowCrop=${field.properties?.['name']}`,
        value: 'ROW_CROP',
      },
      {
        label: (
          <Text size={'14px'} weight={400}>
            {t('fieldFlow.fieldItem.practiceType.options.pasture')}
          </Text>
        ),
        'data-cy': `practice-type-pasture=${field.properties?.['name']}`,
        value: 'PASTURE',
      },
    ];

    const isLegumeSelected = field?.properties?.practices?.indexOf('legume') !== -1;
    const isNoTillageSelected = field?.properties?.practices?.indexOf('noTillage') !== -1;
    const isCoverCroppingSelected = field?.properties?.practices?.indexOf('coverCropping') !== -1;
    const isReducedTillageSelected = field?.properties?.practices?.indexOf('reducedTillage') !== -1;

    const disableITForRegularFlow = disablePracticeSelectionForRegularFlow && !practicesOnContract.find((i) => i.code === 'IT')?.isApplied;
    const disableNTForRegularFlow = disablePracticeSelectionForRegularFlow && !practicesOnContract.find((i) => i.code === 'NT')?.isApplied;
    const disableCCForRegularFlow = disablePracticeSelectionForRegularFlow && !practicesOnContract.find((i) => i.code === 'CC')?.isApplied;
    const disableNRRForRegularFlow = disablePracticeSelectionForRegularFlow && !practicesOnContract.find((i) => i.code === 'NRR')?.isApplied;
    const disableNOForRegularFlow = disablePracticeSelectionForRegularFlow && !practicesOnContract.find((i) => i.code === 'NO')?.isApplied;
    const disableLAForRegularFlow = disablePracticeSelectionForRegularFlow && !practicesOnContract.find((i) => i.code === 'LA')?.isApplied;
    const disableRPBSForRegularFlow = disablePracticeSelectionForRegularFlow && !practicesOnContract.find((i) => i.code === 'RPBS')?.isApplied;
    const disableRPFForRegularFlow = disablePracticeSelectionForRegularFlow && !practicesOnContract.find((i) => i.code === 'RPF')?.isApplied;
    const disableRPIGForRegularFlow = disablePracticeSelectionForRegularFlow && !practicesOnContract.find((i) => i.code === 'RPIG')?.isApplied;

    // 3 new practices for BR flow (Cobertura - Perene, Cobertura - Anuais, Inoculantes)
    const disableCAForRegularFlow = disablePracticeSelectionForRegularFlow && !practicesOnContract.find((i) => i.code === 'CA')?.isApplied;
    const disableCPForRegularFlow = disablePracticeSelectionForRegularFlow && !practicesOnContract.find((i) => i.code === 'CP')?.isApplied;
    const disableINForRegularFlow = disablePracticeSelectionForRegularFlow && !practicesOnContract.find((i) => i.code === 'IN')?.isApplied;

    const disableIT = isNoTillageSelected || (isLegumeSelected && !usStackTillageWithLegumeAddition) || disableITForRegularFlow;
    const disableNT = isReducedTillageSelected || (isLegumeSelected && !usStackTillageWithLegumeAddition) || disableNTForRegularFlow;
    const disableCC = isLegumeSelected || disableCCForRegularFlow || hasICL;
    const disableNRR = !field?.properties?.practices?.length || disableNRRForRegularFlow;
    const disableNO = !field?.properties?.practices?.length || isLegumeSelected || disableNOForRegularFlow;
    const disableLA =
      (isReducedTillageSelected && !usStackTillageWithLegumeAddition) ||
      (isNoTillageSelected && !usStackTillageWithLegumeAddition) ||
      isCoverCroppingSelected ||
      disableLAForRegularFlow ||
      isUpsellingFlow;
    const disableRPBS = disableRPBSForRegularFlow || hasICL;
    const disableRPF = disableRPFForRegularFlow || hasICL;
    const disableRPIG = disableRPIGForRegularFlow || hasICL;
    const disableICL = field.properties?.practices.length && field.properties?.practices.indexOf('ICL') === -1;

    // 3 new practices for BR flow (Cobertura - Perene, Cobertura - Anuais, Inoculantes)
    const disableCA = disableCAForRegularFlow || (field.properties?.practices.length && field.properties?.practices.indexOf('coverAnnual') === -1);
    const disableCP = disableCPForRegularFlow || (field.properties?.practices.length && field.properties?.practices.indexOf('coverPerennial') === -1);
    const disableIN = disableINForRegularFlow || !field.properties?.practices.length;

    const getFirstColumn = () => {
      // Show other practices for brazil when Row Crop is implemented
      if (field.properties?.['practiceType'] === 'ROW_CROP') {
        if (country !== 'br') {
          return (
            <Box width={'50%'} gap={'8px'}>
              <PracticeCheckbox
                field={field}
                practicesOnContract={practicesOnContract}
                saveField={saveField}
                t={t}
                value={'reducedTillage'}
                disabled={disableIT}
                disabledForRegularFlow={disableITForRegularFlow}
              />
              <PracticeCheckbox
                field={field}
                practicesOnContract={practicesOnContract}
                saveField={saveField}
                t={t}
                value={'nitrogenReduction'}
                disabled={disableNRR}
                disabledForRegularFlow={disableNRRForRegularFlow}
              />
              <PracticeCheckbox
                field={field}
                practicesOnContract={practicesOnContract}
                saveField={saveField}
                t={t}
                value={'nitrogenOptimization'}
                disabled={disableNO}
                disabledForRegularFlow={disableNOForRegularFlow}
              />
              <PracticeCheckbox
                field={field}
                practicesOnContract={practicesOnContract}
                saveField={saveField}
                t={t}
                value={'legume'}
                disabled={disableLA}
                disabledForRegularFlow={disableLAForRegularFlow}
              />
            </Box>
          );
        } else {
          return (
            <Box width={'50%'} gap={'8px'}>
              {brRowCropPractice ? (
                <PracticeCheckbox
                  field={field}
                  practicesOnContract={practicesOnContract}
                  saveField={saveField}
                  t={t}
                  disabled={disableCP}
                  value={'coverPerennial'}
                  forceShow={true}
                />
              ) : (
                <PracticeCheckbox
                  field={field}
                  practicesOnContract={practicesOnContract}
                  saveField={saveField}
                  t={t}
                  value={'ICL'}
                  disabled={disableICL}
                  errored={practicesErrored}
                />
              )}
              {brRowCropPractice && (
                <PracticeCheckbox
                  field={field}
                  practicesOnContract={practicesOnContract}
                  saveField={saveField}
                  t={t}
                  disabled={disableIN}
                  value={'inoculant'}
                  forceShow={true}
                  showTooltip={disableIN}
                />
              )}
            </Box>
          );
        }
      } else {
        return (
          <Box width={'50%'} gap={'8px'}>
            <PracticeCheckbox
              field={field}
              practicesOnContract={practicesOnContract}
              saveField={saveField}
              t={t}
              disabled={disableRPBS}
              value={'seeding'}
              disabledForRegularFlow={disableRPBSForRegularFlow}
              errored={practicesErrored}
            />
            <PracticeCheckbox
              field={field}
              practicesOnContract={practicesOnContract}
              saveField={saveField}
              t={t}
              disabled={disableRPF}
              value={'fertilization'}
              disabledForRegularFlow={disableRPFForRegularFlow}
              errored={practicesErrored}
            />
            {brRowCropPractice && country === 'br' && (
              <PracticeCheckbox
                field={field}
                practicesOnContract={practicesOnContract}
                saveField={saveField}
                t={t}
                disabled={disableIN}
                value={'inoculant'}
                forceShow={true}
                showTooltip={disableIN}
              />
            )}
          </Box>
        );
      }
    };

    const getSecondColumn = () => {
      // Show other practices for brazil when Row Crop is implemented
      if (field.properties?.['practiceType'] === 'ROW_CROP') {
        if (country !== 'br') {
          return (
            <Box width={'50%'} gap={'8px'}>
              <PracticeCheckbox
                field={field}
                practicesOnContract={practicesOnContract}
                saveField={saveField}
                t={t}
                value={'noTillage'}
                disabled={disableNT}
                disabledForRegularFlow={disableNTForRegularFlow}
              />
              <PracticeCheckbox
                field={field}
                practicesOnContract={practicesOnContract}
                saveField={saveField}
                t={t}
                value={'coverCropping'}
                disabled={disableCC}
                disabledForRegularFlow={disableCCForRegularFlow}
              />
            </Box>
          );
        } else {
          return (
            <Box width={'50%'} gap={'8px'}>
              {brRowCropPractice && (
                <PracticeCheckbox
                  field={field}
                  practicesOnContract={practicesOnContract}
                  saveField={saveField}
                  t={t}
                  disabled={disableCA}
                  value={'coverAnnual'}
                  forceShow={true}
                />
              )}
              {brRowCropPractice && !hideIclFields && (
                <PracticeCheckbox
                  field={field}
                  practicesOnContract={practicesOnContract}
                  saveField={saveField}
                  t={t}
                  value={'ICL'}
                  disabled={disableICL}
                  errored={practicesErrored}
                />
              )}
            </Box>
          );
        }
      } else {
        return (
          <Box width={'50%'} gap={'8px'}>
            <PracticeCheckbox
              field={field}
              practicesOnContract={practicesOnContract}
              saveField={saveField}
              t={t}
              value={'grazing'}
              disabled={disableRPIG}
              disabledForRegularFlow={disableRPIGForRegularFlow}
              errored={practicesErrored}
            />
            {country === 'br' && (
              <PracticeCheckbox
                field={field}
                practicesOnContract={practicesOnContract}
                saveField={saveField}
                t={t}
                value={'ICL'}
                disabled={disableICL}
                errored={practicesErrored}
              />
            )}
          </Box>
        );
      }
    };

    const getFieldOwnershipOptions = useCallback(() => {
      const otherOption = {
        label: (
          <Text size={'14px'} weight={400}>
            {t('fieldFlow.fieldItem.ownership.options.other')}
          </Text>
        ),
        'data-cy': `ownership-other=${field.properties?.['name']}`,
        value: 'OTHER',
      };
      const options = [
        {
          label: (
            <Text size={'14px'} weight={400}>
              {t('fieldFlow.fieldItem.ownership.options.owned')}
            </Text>
          ),
          'data-cy': `ownership-owned=${field.properties?.['name']}`,
          value: 'OWNED',
        },
        {
          label: (
            <Text size={'14px'} weight={400}>
              {t(`fieldFlow.fieldItem.ownership.options.leased`)}
            </Text>
          ),
          'data-cy': `ownership-leased=${field.properties?.['name']}`,
          value: 'LEASED',
        },
      ];

      if (country !== 'br') {
        options.push(otherOption);
      }
      return options;
    }, [country, field]);

    useEffect(() => {
      setPracticesErrored(syncErrors.missingPractices.indexOf(field.id?.toString() || '') !== -1);
    }, [syncErrors, field.id]);

    const getLegumeMessage = () => {
      if (!practicesOnContract.find((i) => i.code === 'NRR')) {
        return (
          <>
            When <b style={{ fontWeight: 900 }}>Legume Addition</b> is applied{' '}
            <b style={{ fontWeight: 900 }}>only Tillage practices can be selected</b> and the only payment option available is{' '}
            <b style={{ fontWeight: 900 }}>Option A</b>
          </>
        );
      } else {
        return (
          <>
            When <b style={{ fontWeight: 900 }}>Legume Addition</b> is applied{' '}
            <b style={{ fontWeight: 900 }}>only Nitrogen Reduction and Tillage practices can be selected</b> and the only payment option available is{' '}
            <b style={{ fontWeight: 900 }}>Option A</b>
          </>
        );
      }
    };

    return (
      <Box align='start'>
        {!!field.properties?.ineligibleAreaAcres && (
          <Box
            style={{
              background: '#D03450',
              height: '36px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              marginTop: '10px',
            }}
          >
            <Text data-cy={`field-wetlands-overlap-${field.id}`} style={{ color: '#fff', fontWeight: 900, fontSize: '16px', lineHeight: '20px' }}>
              Ineligible area conflicts removed (
              {!field.properties?.area_unit || field.properties?.area_unit.toLowerCase() === 'acres'
                ? Number.parseFloat(field.properties?.ineligibleAreaAcres).toFixed(2)
                : Number.parseFloat(field.properties?.ineligibleAreaHectares).toFixed(2)}{' '}
              {field.properties?.area_unit || 'acres'})
            </Text>
          </Box>
        )}

        <Box style={{ width: '100%' }}>
          <Box
            id={`field-${field.id}`}
            key={`field-${field.id}`}
            border={{ side: 'bottom', color: '#ccc', size: '1px' }}
            pad={{ bottom: '16px' }}
            height={{ min: 'auto' }}
            style={{
              width: '100%',
            }}
            gap={'16px'}
            focusIndicator={false}
            onClick={() => focusField(field)}
          >
            <Box direction={'row'} align={'center'} margin={{ top: '18px' }}>
              <FieldThumbnail feature={field} height={36} width={36} />
              <Box style={{ marginLeft: '8px' }} width={'43.4%'} height={'36px'}>
                <TextInput
                  data-cy={`field-name-input-${field.id}`}
                  plain
                  focusIndicator={false}
                  size={'16px'}
                  height={'36px'}
                  style={{
                    lineHeight: '16px',
                    padding: '6px',
                    border: '1px solid #808080',
                    borderRadius: '0',
                    fontWeight: '800',
                    flexGrow: '1.02',
                  }}
                  value={field.properties!['name'] ?? ''}
                  onChange={(event) => {
                    event.stopPropagation();
                    field.properties!['name'] = event.target.value;
                    saveField(field);
                  }}
                />
              </Box>

              <Box style={{ marginLeft: '16px' }} width={'42.8%'} height={'36px'}>
                <TextInput
                  data-cy={`field-area-input-${field.id}`}
                  plain
                  focusIndicator={false}
                  size={'16px'}
                  height={'36px'}
                  disabled={true}
                  style={{ lineHeight: '16px', padding: '6px', border: '1px solid #808080', borderRadius: '0', fontWeight: '800' }}
                  value={field.properties![i18next.language === 'pt-BR' ? 'areaHectare' : 'area'].toFixed(2) ?? ''}
                />
              </Box>
              {!producerData?.caseStatusHistory?.find((s: any) => s.status === 'In Support') || userRegion === 'BR' ? (
                <StyledTooltip label={t(`fieldFlow.mainWizard.Fields.deleteTooltip`)} showToolTip={field.properties?.editing}>
                  <img
                    data-cy={`delete-field-button-${field.id}`}
                    src={deleteIcon}
                    height={24}
                    width={24}
                    alt='Delete Icon'
                    style={{
                      opacity: field.properties?.editing ? '0.5' : '1',
                      marginLeft: '16px',
                      cursor: field.properties?.editing ? 'default' : 'pointer',
                    }}
                    onClick={(event) => {
                      if (field.properties?.editing) return;
                      event.stopPropagation();
                      deleteField(field);
                    }}
                  />
                </StyledTooltip>
              ) : (
                <StyledTooltip label={t(`fieldFlow.mainWizard.Fields.unenrollTooltip`)} showToolTip={true} dropProps={{ align: { bottom: 'top' } }}>
                  <img
                    data-cy={`enrollment-field-button-${field?.properties?.name}`}
                    src={unenrollIcon}
                    height={24}
                    width={24}
                    alt='Unenrollment Icon'
                    style={{
                      opacity: field.properties?.editing ? '0.5' : '1',
                      marginLeft: '16px',
                      cursor: field.properties?.editing ? 'default' : 'pointer',
                    }}
                    onClick={(event) => {
                      if (field.properties?.editing) return;
                      event.stopPropagation();
                      setShowUnenrollModal(true);
                    }}
                  />
                </StyledTooltip>
              )}
            </Box>

            {showUnenrollModal && (
              <Layer
                style={{ border: '1px solid #CCCCCC', borderRadius: '12px' }}
                onEsc={() => setShowUnenrollModal(false)}
                onClickOutside={() => setShowUnenrollModal(false)}
              >
                <Box alignSelf={'center'} align={'center'} pad={'24px'} gap={'24px'} width={'400px'} data-cy={'unenroll-modal'}>
                  <Box alignSelf={'center'} align={'center'} gap={'8px'}>
                    <Text style={{ fontSize: '16px', fontWeight: '900' }} alignSelf='center'>
                      {t('fieldFlow.mainWizard.Fields.unenrollModal.title')}
                    </Text>
                    <Text style={{ fontSize: '12px', fontWeight: '400', textAlign: 'center' }}>
                      {t('fieldFlow.mainWizard.Fields.unenrollModal.content')}
                    </Text>
                  </Box>
                  <Box width={'100%'} direction={'row'} gap={'12px'}>
                    <Button
                      plain
                      data-cy={'unenroll-modal-cancel'}
                      label={t('fieldFlow.mainWizard.Fields.unenrollModal.cancelButton')}
                      onClick={() => {
                        setShowUnenrollModal(false);
                      }}
                      className={'secondary-button'}
                      style={{
                        padding: '8px 18px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #282828',
                        fontWeight: '900',
                        fontSize: '16px',
                        flexGrow: 1,
                        textAlign: 'center',
                      }}
                    />
                    <Button
                      plain
                      data-cy={'unenroll-modal-confirm'}
                      id={'resubmit-contract'}
                      type='submit'
                      label={t('fieldFlow.mainWizard.Fields.unenrollModal.confirmButton')}
                      onClick={($event) => {
                        $event.stopPropagation();
                        changeFieldEnrollmentStatus(field, !field.properties?.unenrolledAt, hidden);
                        setShowUnenrollModal(false);
                      }}
                      className={'primary-button'}
                      style={{
                        padding: '8px 18px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#D03450',
                        fontWeight: '900',
                        fontSize: '16px',
                        color: 'white',
                        flexGrow: 1,
                        textAlign: 'center',
                      }}
                    />
                  </Box>
                </Box>
              </Layer>
            )}

            {field.properties?.['practices'] && field.properties['practices'].indexOf('legume') !== -1 && (
              <Box
                direction={'row'}
                align={'center'}
                margin={{ bottom: '4px' }}
                style={{
                  background: 'rgb(208, 52, 80, 0.2)',
                  borderRadius: '10px',
                }}
              >
                <Text
                  style={{
                    color: '#282828',
                    textAlign: 'center',
                    fontFamily: 'Avenir',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '14px',
                    letterSpacing: '0.07em',
                    padding: '.5rem',
                  }}
                >
                  {getLegumeMessage()}
                </Text>
              </Box>
            )}

            <Box gap={'4px'}>
              <Text textAlign={'start'} weight={400} size={'16px'} color={'#282828'} style={{ lineHeight: '24px' }}>
                {t('fieldFlow.fieldItem.ownership.title')}
              </Text>

              <RadioButtonGroup
                direction={'row'}
                name={'situation'}
                data-cy={`field-situation-${field.properties?.['name']}`}
                value={field.properties?.['situation']}
                onChange={(event) => saveField({ ...field, properties: { ...field.properties, situation: event.target.value } })}
                options={getFieldOwnershipOptions()}
              />
            </Box>

            <Box gap={'4px'}>
              <Box direction={'row'}>
                <Text textAlign={'start'} weight={400} size={'16px'} color={'#282828'} style={{ lineHeight: '24px' }}>
                  {t('fieldFlow.fieldItem.practiceType.title')}
                </Text>
                {country === 'br' ? (
                  <StyledTooltip label={t(`fieldFlow.fieldItem.practiceType.tooltip`)} showToolTip={true} dropProps={{ align: { bottom: 'top' } }}>
                    <Image
                      height={24}
                      width={24}
                      style={{
                        filter: 'brightness(0) saturate(100%) invert(15%) sepia(0%) saturate(0%) hue-rotate(179deg) brightness(99%) contrast(98%)',
                      }}
                      src={infoIcon}
                    />
                  </StyledTooltip>
                ) : (
                  <></>
                )}
              </Box>

              <RadioButtonGroup
                direction={'row'}
                name={'practiceType'}
                data-cy={`field-practiceType-${field.properties?.['name']}`}
                value={field.properties?.['practiceType']}
                onChange={(event) => {
                  const practices = country === 'br' && hasICL ? ['ICL'] : [];
                  saveField({ ...field, properties: { ...field.properties, practiceType: event.target.value, practices } });
                }}
                options={practiceTypes}
              />
            </Box>

            {country !== 'br' && (
              <Box gap={'4px'}>
                <Text textAlign={'start'} weight={400} size={'16px'} color={'#282828'} style={{ lineHeight: '24px' }}>
                  {t('fieldFlow.fieldItem.paymentOption.title')}
                </Text>

                <StyledTooltip
                  label={t('fieldFlow.fieldItem.paymentOption.annualTooltip')}
                  showToolTip={field.properties?.paymentOption === 'ANNUAL'}
                >
                  <Box width={'fit-content'}>
                    <RadioButtonGroup
                      direction={'row'}
                      data-cy={`field-paymentOption-${field.properties?.['name']}`}
                      disabled={field.properties?.paymentOption === 'ANNUAL'}
                      name={'paymentOption'}
                      id={'paymentOption' + field.id}
                      value={field.properties?.['paymentOption']}
                      onChange={(event) => saveField({ ...field, properties: { ...field.properties, paymentOption: event.target.value } })}
                      options={
                        field.properties?.paymentOption === 'ANNUAL'
                          ? [
                              {
                                label: (
                                  <Text size={'14px'} weight={400} color={hasPaymentError && !field.properties?.paymentOption ? '#D03450' : ''}>
                                    {t('fieldFlow.fieldItem.paymentOption.options.annual')}
                                  </Text>
                                ),
                                //@ts-ignore
                                'data-cy': `payment-annual=${field.properties['name']}`,
                                value: 'ANNUAL',
                              },
                            ]
                          : [
                              {
                                label: (
                                  <Text size={'14px'} weight={400} color={hasPaymentError && !field.properties?.paymentOption ? '#D03450' : ''}>
                                    {t('fieldFlow.fieldItem.paymentOption.options.optionA')}
                                  </Text>
                                ),
                                //@ts-ignore
                                'data-cy': `payment-optionA=${field.properties['name']}`,
                                value: 'OPTION_A',
                              },
                              {
                                label: (
                                  <Text size={'14px'} weight={400} color={hasPaymentError && !field.properties?.paymentOption ? '#D03450' : ''}>
                                    {t('fieldFlow.fieldItem.paymentOption.options.optionB')}
                                  </Text>
                                ),
                                //@ts-ignore
                                'data-cy': `payment-optionB=${field.properties['name']}`,
                                value: 'OPTION_B',
                                disabled: isLegumeSelected,
                              },
                            ]
                      }
                    />
                  </Box>
                </StyledTooltip>
              </Box>
            )}

            <Box gap={'4px'}>
              <Text textAlign={'start'} weight={400} size={'16px'} color={'#282828'} style={{ lineHeight: '24px' }}>
                {t('fieldFlow.fieldItem.irrigation.title')}
              </Text>

              <RadioButtonGroup
                direction={'row'}
                data-cy={`field-irrigated-${field.properties?.['name']}`}
                name={'irrigated'}
                value={field.properties?.['irrigated']}
                onChange={() => saveField({ ...field, properties: { ...field.properties, irrigated: !field.properties!['irrigated'] } })}
                options={[
                  {
                    label: (
                      <Text size={'14px'} weight={400}>
                        {t('fieldFlow.fieldItem.irrigation.options.irrigated')}
                      </Text>
                    ),
                    value: true,
                  },
                  {
                    label: (
                      <Text size={'14px'} weight={400}>
                        {t('fieldFlow.fieldItem.irrigation.options.nonIrrigated')}
                      </Text>
                    ),
                    value: false,
                  },
                ]}
              />
            </Box>

            <Box gap={'8px'} id={'practices-' + field.id}>
              <Text textAlign={'start'} weight={400} size={'16px'} color={'#282828'} style={{ lineHeight: '24px' }}>
                {t('fieldFlow.fieldItem.practices.title')}
              </Text>
              <Box direction={'row'}>
                {/*First Column of Practices*/}
                {getFirstColumn()}

                {/*Second Column of Practices*/}
                {getSecondColumn()}
              </Box>
            </Box>
            {field.properties?.['practices'] && field.properties['practices'].indexOf('coverCropping') !== -1 && country !== 'br' && (
              <Box gap={'4px'}>
                <Text textAlign={'start'} weight={400} size={'16px'} color={'#282828'} style={{ lineHeight: '24px' }}>
                  {t('fieldFlow.fieldItem.legume.title')}
                </Text>

                <RadioButtonGroup
                  direction={'row'}
                  name={'legume'}
                  value={field.properties?.['legume']}
                  onChange={(event) => {
                    saveField({ ...field, properties: { ...field.properties, legume: event.target.value === 'true' } });
                  }}
                  options={[
                    {
                      label: (
                        <Text size={'14px'} weight={400}>
                          {t('fieldFlow.fieldItem.legume.options.legume')}
                        </Text>
                      ),
                      value: true,
                    },
                    {
                      label: (
                        <Text size={'14px'} weight={400}>
                          {t('fieldFlow.fieldItem.legume.options.nonLgume')}
                        </Text>
                      ),
                      value: false,
                    },
                  ]}
                />
              </Box>
            )}
            {field.properties?.['practices'] &&
              (field.properties['practices'].indexOf('noTillage') !== -1 || field.properties['practices'].indexOf('reducedTillage') !== -1) && (
                <Box gap={'4px'}>
                  <Text textAlign={'start'} weight={400} size={'16px'} color={'#282828'} style={{ lineHeight: '24px' }}>
                    {t('fieldFlow.fieldItem.tillage.title')}
                  </Text>

                  <RadioButtonGroup
                    direction={'row'}
                    name={'previousTillage'}
                    value={field.properties?.['previousTillage']}
                    onChange={(event) => saveField({ ...field, properties: { ...field.properties, previousTillage: event.target.value } })}
                    options={[
                      {
                        label: (
                          <Text size={'14px'} weight={400}>
                            {t('fieldFlow.fieldItem.tillage.options.reduced')}
                          </Text>
                        ),
                        value: 'reduced',
                      },
                      {
                        label: (
                          <Text size={'14px'} weight={400}>
                            {t('fieldFlow.fieldItem.tillage.options.intensive')}
                          </Text>
                        ),
                        value: 'intensive',
                      },
                    ]}
                  />
                </Box>
              )}

            {i18next.language !== 'pt-BR' && (
              <Box gap={'8px'}>
                <Text textAlign={'start'} weight={400} size={'16px'} color={'#282828'} style={{ lineHeight: '24px' }}>
                  {t('fieldFlow.fieldItem.history.title')}
                </Text>

                <Box height={'36px'}>
                  <TextInput
                    data-cy={`historical-practices=${field.properties?.['name']}`}
                    plain
                    focusIndicator={false}
                    size={'16px'}
                    height={'36px'}
                    style={{ lineHeight: '16px', padding: '6px', border: '1px solid #808080', borderRadius: '0', fontWeight: '800' }}
                    value={field.properties!['historicalPractices'] ?? ''}
                    onChange={(event) => {
                      event.stopPropagation();
                      field.properties!['historicalPractices'] = event.target.value;
                      saveField(field);
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  },
);
