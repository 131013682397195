// @ts-ignore
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Control } from 'mapbox-gl';
import * as React from 'react';

function noop(): void {
  /* do nothing */
}

type DrawHandler = (event: any) => void;

/**
 * User-facing props passed to <DrawControl />
 */
export interface DrawControlProps {
  defaultMode?: string;
  displayControlsDefault?: boolean;
  modes?: object;
  onDrawActionable?: DrawHandler;
  onDrawCombine?: DrawHandler;
  onDrawCreate?: DrawHandler;
  onDrawDelete?: DrawHandler;
  onDrawModeChange?: DrawHandler;
  onDrawRender?: DrawHandler;
  onDrawSelectionChange?: DrawHandler;
  onDrawUncombine?: DrawHandler;
  onDrawUpdate?: DrawHandler;
  position?: 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
  styles?: any[];
  map: mapboxgl.Map;
}

export default class DrawControl extends React.Component<DrawControlProps> {
  map?: mapboxgl.Map;
  draw?: Control;

  override componentWillUnmount(): void {
    const map = this.props.map;
    if (!map?.getStyle()) {
      return;
    }

    if (!this.draw) {
      return;
    }
    map.removeControl(this.draw);
  }

  setMap(map: mapboxgl.Map) {
    if (this.map !== map) {
      if (this.draw) {
        try {
          this.map?.removeControl(this.draw);
        } catch (e) {
          // ignore error if not able to remove draw
          console.log(e);
        }
      }
      this.map = map;
    } else if (this.draw) {
      return;
    }

    const {
      modes,
      onDrawActionable,
      onDrawCombine,
      onDrawCreate,
      onDrawDelete,
      onDrawModeChange,
      onDrawRender,
      onDrawSelectionChange,
      onDrawUncombine,
      onDrawUpdate,
      position,
    } = this.props;

    // Define a new Draw Control
    this.draw = new MapboxDraw({
      ...this.props,
      map: undefined,
      keybindings: true,
      controls: {
        trash: true,
      },
      modes: {
        ...MapboxDraw.modes,
        ...modes,
      },
    });

    // Add it to our map
    map.addControl(this.draw as Control, position);

    // Hook draw events
    map.on('draw.actionable', onDrawActionable || noop);
    map.on('draw.combine', onDrawCombine || noop);
    map.on('draw.create', onDrawCreate || noop);
    map.on('draw.delete', onDrawDelete || noop);
    map.on('draw.modechange', onDrawModeChange || noop);
    map.on('draw.render', onDrawRender || noop);
    map.on('draw.selectionchange', onDrawSelectionChange || noop);
    map.on('draw.uncombine', onDrawUncombine || noop);
    map.on('draw.update', onDrawUpdate || noop);
  }

  override render(): null {
    this.setMap(this.props.map);

    return null;
  }
}
