import React, { useCallback, useState } from 'react';
import { Box, Image } from 'grommet';
import DrawingPanel from '../Drawing/DrawingPanel';
import { customModes, MAP_MODES } from '../Drawing/modes/modes';
import drawStyles from './drawStyles';
import { useMapContext } from '../../service/map/MapContext';
import AgoroGeoJSONList from '../GeoJSON/AgoroGeoJSONList';
import { useNavigate, useParams } from 'react-router-dom';
import DrawControl from './DrawControl';
import MapV from 'react-map-gl';
import * as turf from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css';
import { DeleteField } from '../Modals/DeleteField';
import { AddressSearchComponent } from '../Search/AddressSearchComponent';
import mapboxgl from 'mapbox-gl';
import { UploadModal } from '../Modals/UploadModal';
import Wizard from '../Wizard/Wizard';
import InformationPanel from '../InformationPanel/InformationPanel';
import arrowBack from '../../../../assets/BoundaryTool/arrow-back.svg';
import { navigateBackToProfile, getPaddedBBox } from '../../service/common/utils';
import { fitBoundsOption } from '../../service/map/MapService';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { CoordinateSearchComponent } from '../Search/CoordinateSearchComponent';
import { useTranslation } from 'react-i18next';
import { ShowCloserFieldsComponents } from '../Search/ShowCloserFieldsComponent';
import { FormDown, FormUp } from 'grommet-icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';

const addLayerToMap = (map, id: string, type: any, source: any, paint: any, layout: any) => {
  if (!map) {
    return;
  }
  if (!map?.getLayer(id)) {
    const layer: any = {
      id: id,
      type: type,
      source: source,
    };
    if (paint) {
      layer.paint = paint;
    }
    if (layout) {
      layer.layout = layout;
    }
    map?.addLayer(layer);
  }
};

const addSourceToMap = (map, id: string, showLabel, areaAttribute) => {
  if (!map) {
    return;
  }
  if (!map?.getSource(id)) {
    map?.addSource(id, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
  }
  addLayerToMap(
    map,
    `${id}-outline`,
    'line',
    id,
    {
      'line-color': ['get', 'lineColor'],
      'line-width': ['get', 'lineWidth'],
    },
    null,
  );

  addLayerToMap(
    map,
    `${id}-fill`,
    'fill',
    id,
    {
      'fill-color': ['get', 'fillColor'],
      'fill-opacity': ['get', 'fillOpacity'],
    },
    null,
  );

  if (showLabel) {
    addLayerToMap(
      map,
      `${id}-label`,
      'symbol',
      id,
      {
        'text-color': '#fff',
        'text-halo-color': '#282828',
        'text-halo-width': 2.5,
      },
      {
        'text-field': ['get', 'name'],
        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-size': [
          'interpolate',
          ['linear'],
          ['zoom'],
          2,
          ['max', ['/', ['ln', ['get', areaAttribute]], ['ln', 10000]], 0],
          10,
          ['max', ['/', ['ln', ['get', areaAttribute]], ['ln', 1.8]], 0],
          14,
          ['+', 5, ['max', ['/', ['ln', ['get', areaAttribute]], ['ln', 1.2]], 0]],
          16,
          ['+', 8, ['max', ['/', ['ln', ['get', areaAttribute]], ['ln', 1.1]], 0]],
        ],
      },
    );
  }
};

export const AgoroMap = () => {
  const {
    loading,
    state,
    map,
    mode,
    setMap,
    country,
    setDraw,
    fields,
    layouts,
    removeField,
    removeAllFields,
    startSplittingField,
    startEditingField,
    deleteField,
    setDeleteField,
    deleteAllFields,
    setDeleteAllFields,
    onDrawUpdate,
    onModeChange,
    wizardStep,
    showConfirmationModal,
    showUploadModal,
    setShowUploadModal,
    setShowConfirmationModal,
    restartPaddockBoundary,
    willLoseProgressOnExit,
  } = useMapContext();

  const { t, i18n } = useTranslation();
  const { journeyId } = useParams();
  const [showBackModal, setShowBackModal] = useState<boolean>(false);
  const [panelClosed, setPanelClosed] = useState<boolean>(false);
  const navigate = useNavigate();

  const { cpBoundaryTool } = useFlags();

  const isChannelPartner = useSelector((state: any) => {
    return !!state.user.userData.channelPartnerId;
  });

  if (isChannelPartner && !cpBoundaryTool) {
    navigateBackToProfile(journeyId || '', navigate);
  }

  const updateMapFromAddress = (bbox: number[], map: mapboxgl.Map) => {
    if (bbox && bbox.length === 4) {
      map.fitBounds([bbox[0], bbox[1], bbox[2], bbox[3]], fitBoundsOption);
    } else if (bbox && bbox.length === 2) {
      map.fitBounds([bbox[0], bbox[1]], fitBoundsOption);
    }
  };

  const changeMapPosition = React.useCallback(
    (bbox: number[]) => {
      updateMapFromAddress(bbox, map!);
    },
    [map],
  );

  const showDrawingTools = useCallback(() => {
    const isSetup = state === 'FIRST_SETUP' || state === 'SECOND_SETUP';
    return isSetup && (wizardStep === 'FIELD' || (wizardStep === 'PADDOCK' && (mode === MAP_MODES.SPLIT_FIELD || mode === MAP_MODES.PASSING_MODE)));
  }, [state, wizardStep, mode]);

  addSourceToMap(map, 'wetlands', true, 'area');
  addSourceToMap(map, 'public-lands', true, 'area');
  addSourceToMap(map, 'near-fields', false, null);
  addSourceToMap(map, 'paddocks', true, 'area');
  addSourceToMap(map, 'fields', true, 'area');

  return (
    <Box>
      {showConfirmationModal && (
        <ConfirmationModal
          dataCy={'delete-all-paddocks-modal'}
          confirm={() => {
            setShowConfirmationModal(false);
            restartPaddockBoundary();
          }}
          cancel={() => setShowConfirmationModal(false)}
          message={t('fieldFlow.deletePaddocks.text')}
        />
      )}
      {showBackModal && (
        <ConfirmationModal
          dataCy={'leave-field-flow-modal'}
          confirm={() => {
            if (journeyId) navigateBackToProfile(journeyId, navigate);
          }}
          cancel={() => setShowBackModal(false)}
          title={t('fieldFlow.leaveModal.title')}
          message={t('fieldFlow.leaveModal.text')}
          confirmLabel={t('fieldFlow.leaveModal.confirm')}
          cancelLabel={t('fieldFlow.leaveModal.cancel')}
        />
      )}
      {showUploadModal && (
        <UploadModal
          journeyId={journeyId ?? ''}
          country={country}
          onContinue={() => {
            if (fields.length) {
              const newCenter = getPaddedBBox(
                turf.bbox(turf.featureCollection(fields.map((f) => f.properties?.processedProperties?.original.boundaries ?? f))),
              );
              map?.fitBounds([newCenter[0], newCenter[1], newCenter[2], newCenter[3]], fitBoundsOption);
            }
            setShowUploadModal(false);
          }}
          onBack={() => {
            setShowUploadModal(false);
          }}
        />
      )}
      {(deleteField || deleteAllFields) && (
        <DeleteField
          deleteAllFields={deleteAllFields}
          onCancel={() => {
            if (deleteAllFields) {
              setDeleteAllFields(false);
            } else {
              setDeleteField(undefined);
            }
          }}
          onDelete={() => {
            if (deleteAllFields) {
              removeAllFields();
              setDeleteAllFields(false);
            } else {
              removeField(deleteField!);
              setDeleteField(undefined);
            }
          }}
        />
      )}
      {/*todo fix map size*/}
      <MapV
        initialViewState={{
          bounds:
            i18n.language !== 'pt-BR'
              ? [-171.791110603, 18.91619, -66.96466, 71.3577635769]
              : [-82.4117709445771, -44.60369527891214, 14.628196910351932, 17.219142546755293],
        }}
        mapboxAccessToken={'' + process.env['REACT_APP_MAP_BOX_KEY']}
        mapStyle='mapbox://styles/mapbox/satellite-streets-v11?optimize=true'
        style={{ height: '100vh', width: '100%', position: 'fixed' }}
        interactiveLayerIds={state !== 'FIRST_SETUP' && state !== 'SECOND_SETUP' ? [] : ['fields-fill']}
        onClick={(e) => {
          if (!wizardStep) {
            return;
          }
          const f = map?.queryRenderedFeatures(e.point, { layers: ['fields-fill'] });
          const fieldId = f?.length ? f[0].properties?.parent : '';
          if (!fields.find((i) => i.id === fieldId)) {
            // near field
            return;
          }
          if (wizardStep === 'PADDOCK') {
            startSplittingField(fieldId);
          } else {
            document.getElementById(`field-${fieldId}`)?.scrollIntoView({ behavior: 'smooth' });
            startEditingField(fieldId);
          }
        }}
        onLoad={(e) => setMap(e.target as mapboxgl.Map)}
      >
        <AgoroGeoJSONList />
      </MapV>
      {map && layouts && (
        <DrawControl
          map={map}
          ref={setDraw}
          defaultMode={MAP_MODES.STATIC}
          displayControlsDefault={false}
          modes={customModes}
          styles={drawStyles}
          onDrawUpdate={onDrawUpdate}
          onDrawModeChange={onModeChange}
        />
      )}

      {map && layouts && (
        <>
          <Box
            width={'fit-content'}
            style={{
              position: 'absolute',
              zIndex: 11,
              top: '24px',
              left: '24px',
            }}
          >
            <Box
              direction={'row'}
              gap={'16px'}
              style={{
                position: 'relative',
              }}
            >
              <Box
                width={'48px'}
                height={'48px'}
                justify={'center'}
                align={'center'}
                round={'5px'}
                data-cy={'back-to-profile'}
                onClick={() => {
                  if (willLoseProgressOnExit) setShowBackModal(true);
                  else if (journeyId) navigateBackToProfile(journeyId, navigate);
                }}
                border={{ color: '#808080', size: '1px' }}
                background={'#ffffff'}
              >
                <Image src={arrowBack} width={'24px'} height={'24px'} />
              </Box>
              <Box
                width={'20vw'}
                height={'48px'}
                round={'5px'}
                style={{
                  maxWidth: '280px',
                  background: '#ffffff',
                }}
              >
                <AddressSearchComponent addressSelectedBBoxCallback={changeMapPosition} country={country} />
              </Box>
              <CoordinateSearchComponent map={map} />
              {state !== 'OVERVIEW_V2' && !isChannelPartner && <ShowCloserFieldsComponents />}
            </Box>
          </Box>
          {showDrawingTools() && (
            <Box
              id={'box-drawing-tool'}
              direction={'row'}
              width={'calc(60vw - 24px)'}
              justify={'center'}
              style={{
                position: 'absolute',
                bottom: '24px',
                minWidth: 'calc(100vw - 824px)',
                maxWidth: 'calc(100vw - 584px)',
                left: panelClosed ? `calc(50% - ${Number(document.getElementById('box-drawing-tool')?.offsetWidth) / 2}px)` : '24px',
                zIndex: 11,
              }}
            >
              <DrawingPanel />
            </Box>
          )}
        </>
      )}

      {layouts && (state === 'FIRST_SETUP' || state === 'SECOND_SETUP') && !panelClosed && <Wizard />}
      {layouts && (state === 'OVERVIEW' || state === 'OVERVIEW_V2' || state === 'DETAILS') && !panelClosed && <InformationPanel />}

      {!loading && (
        <Box
          background={'#fff'}
          data-cy={'toggle-fields-list'}
          style={{
            position: 'absolute',
            right: panelClosed ? '24px' : '48px',
            top: panelClosed ? '24px' : '48px',
            zIndex: 1,
            padding: '24px',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '48px',
            height: '48px',
            border: '1px solid rgb(40, 40, 40)',
          }}
          onClick={() => setPanelClosed(!panelClosed)}
        >
          {panelClosed && <FormDown color='#282828' />}
          {!panelClosed && <FormUp color='#282828' />}
        </Box>
      )}
    </Box>
  );
};
