import { Box, Image, Text } from 'grommet';
import reducedTillageIcon from '../../../../assets/BoundaryTool/practices/reducedTillageIcon.svg';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMapContext } from '../../service/map/MapContext';
import { useTranslation } from 'react-i18next';

export const OffsiteDropdown = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { journeyId } = useParams();
  const { setState } = useMapContext();

  return (
    <Box style={{ minHeight: 'unset' }}>
      <Box
        style={{
          borderRadius: '5px',
          border: '1px solid #CCC',
          padding: '0.5rem 0.75rem',
        }}
      >
        <Box direction={'row'} justify={'between'}>
          <Box
            direction={'row'}
            align={'center'}
            onClick={() => {
              setState('DETAILS');
              navigate(`/v2/fields/${journeyId}?fieldId=OFFSITE`);
            }}
          >
            <Image height={'24px'} width={'24px'} src={reducedTillageIcon} />
            <Text margin={'0 0.75rem'} weight={700} size={'1.5rem'}>
              Offsite
            </Text>
            <Box pad={'6px 8px'} background={'#FFE137'}>
              <Text weight={700} style={{ boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)' }}>
                {t('fieldFlow.ranchOverview.offsiteDetails')}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OffsiteDropdown;
