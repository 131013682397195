import { useTranslation } from 'react-i18next';
import { Box, Button, Spinner, Text } from 'grommet';
import { StyledTooltip } from '../../common/StyledTooltip';
import { useMapContext } from '../../../service/map/MapContext';
import { Step } from '../../../types/Step';
import { showToast } from '../../../service/common/utils';
import { useEffect, useRef, useState } from 'react';
import { TFunction } from 'i18next';
import { scrollToError } from '../WizardHelper';
import SyncFieldsModal from '../../Modals/SyncFieldsModal';
import SyncErrorModal from '../../Modals/SyncErrorModal/SyncErrorModal';
import EstimateErrorModal from '../../Modals/EstimateErrorModal';
import { EnumProcessingStatus } from '../../../service/common/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FieldProcessingModal from '../../Modals/FieldProcessingModal';
import FinalizeIneligibleAreasModal from '../../Modals/FinalizeIneligibleAreasModal';

interface NextStepButtonProps {
  steps: Step[];
  currentStep: Step;
  stepIndex: number;
  showLoading: boolean;
  setStepIndex: (value: ((prevState: number) => number) | number) => void;
  setShowDateRangeModal: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setShowLoading: (value: ((prevState: boolean) => boolean) | boolean) => void;
}

const NextStepButton = ({ steps, currentStep, stepIndex, showLoading, setStepIndex, setShowDateRangeModal, setShowLoading }: NextStepButtonProps) => {
  const { t } = useTranslation();
  const {
    fields,
    enableFinalize,
    overlaps,
    producerData,
    state,
    invalidSteps,
    focusField,
    saveFields,
    upsertShapefile,
    centerMap,
    setWizardStep,
    setNotSavedPaddockVersion,
    saveVersion,
    saveHerds,
    clearHerds,
    setState,
    country,
    willLoseProgressOnExit,
    setWillLoseProgressOnExit,
    shapefileStatus,
    getSyncAvailability,
    getSyncAvailabilityEstimate,
    syncErrors,
    processingStatus,
    startFieldProcessing,
    getFieldProcessingStatus,
    ineligibleAreasFinalized,
  } = useMapContext();
  const { enableAsyncFieldProcessing } = useFlags();

  const [showFinalizeModal, setShowFinalizeModal] = useState<boolean>(false);
  const [showSyncErrorModal, setShowSyncErrorModal] = useState<boolean>(false);
  const [showEstimatesModal, setShowEstimatesModal] = useState<boolean>(false);
  const [showFieldProcessingModal, setShowFieldProcessingModal] = useState<boolean>(false);
  const [showFinalizeIneligibleAreasModal, setShowFinalizeIneligibleAreasModal] = useState<boolean>(false);
  const [loadingSync, setLoadingSync] = useState<boolean>(false);

  const saveFunctions = useRef<(() => Promise<void>)[]>([]);

  const getHasNewPracticesBR = (): boolean => {
    return (
      country === 'br' &&
      !!fields.find((field) => {
        return field.properties?.practices.find((p) => ['coverPerennial', 'inoculant'].indexOf(p) !== -1);
      })
    );
  };
  const [disableSyncNewPractices, setDisableSyncNewPractices] = useState<boolean>(getHasNewPracticesBR());

  useEffect(() => {
    setDisableSyncNewPractices(getHasNewPracticesBR());
  }, [fields]);

  async function fieldStepForward(skip?: boolean) {
    focusField();
    if (!skip) {
      await saveFields();
      try {
        await upsertShapefile('APPROVED');
      } catch (e) {
        showToast(t('fieldFlow.errors.error'), t('fieldFlow.errors.salesForceUpdate'), 'error', 'sync-error');
      }
    }
    centerMap();
    setWizardStep('PADDOCK');
    setShowDateRangeModal(true);
  }

  function paddockStepForward() {
    setNotSavedPaddockVersion();
    saveFunctions.current = [...saveFunctions.current, saveVersion];
    setWizardStep(undefined);
  }

  function herdStepForward(skip?: boolean) {
    if (!skip) {
      saveFunctions.current = [...saveFunctions.current, saveHerds];
    } else {
      clearHerds();
    }
    setWizardStep(undefined);
  }

  async function stepForward(skip?: boolean) {
    setShowLoading(true);
    const stepFunction: Record<number, () => Promise<any>> = {
      0: async () => await fieldStepForward(skip),
      1: async () => paddockStepForward(),
      2: async () => await herdStepForward(skip),
    };

    await stepFunction[stepIndex]();
    if (stepIndex !== steps.length - 1) {
      setShowLoading(false);
      setStepIndex((stepIndex) => stepIndex + 1 + (steps[stepIndex + 1].ignoreStep ? 1 : 0));
    } else {
      for (const saveFunction of saveFunctions.current) {
        await saveFunction();
      }
      saveFunctions.current = [];
      setShowLoading(false);
      setWillLoseProgressOnExit(false);
      setState('OVERVIEW');
    }
  }

  async function fieldNextStep(
    t: TFunction<'translation'>,
    setShowEstimatesModal: (bool) => void,
    setShowFinalizeModal: (bool) => void,
    setShowSyncErrorModal: (bool) => void,
    setShowFieldProcessingModal: (bool) => void,
    setShowFinalizeIneligibleAreasModal: (bool) => void,
  ) {
    try {
      setLoadingSync(true);
      if (willLoseProgressOnExit) await saveFields();

      if (enableAsyncFieldProcessing && country === 'us' && processingStatus === EnumProcessingStatus.NOT_PROCESSED) {
        setShowFieldProcessingModal(true);
        await startFieldProcessing();
        await getFieldProcessingStatus(() => setShowFieldProcessingModal(false));
        return;
      }

      if (enableAsyncFieldProcessing && country === 'us' && processingStatus === EnumProcessingStatus.PROCESSED && !ineligibleAreasFinalized) {
        setShowFinalizeIneligibleAreasModal(true);
        return;
      }

      if (shapefileStatus !== 'GATHERING') await upsertShapefile('GATHERING');
      const syncResponse = await getSyncAvailability();

      if (syncResponse.status !== 'OK' && syncResponse.missingPractices.length) {
        showToast('Erro', 'Por favor preencha todos os campos para poder finalizar os limites', 'error', 'payment-option-toast');
        scrollToError('practices-' + syncResponse.missingPractices[0]);
        return;
      }
      if (country === 'br') {
        const available = getSyncAvailabilityEstimate();
        if (!available) {
          setShowEstimatesModal(true);
          return;
        }
      }
      // sync availability
      if (syncResponse.status === 'OK') {
        setShowFinalizeModal(true);
      }
      if (syncResponse.status !== 'OK' && syncResponse.paymentOptionErrors.length) {
        showToast('Error', 'Please fill the PAYMENT OPTION for all fields', 'error', 'payment-option-toast');
        scrollToError(`paymentOption${syncErrors.paymentOptionErrors[0]?.id}`);
      } else if (syncResponse.status !== 'OK' && syncResponse.practices.length) {
        setShowSyncErrorModal(true);
      }
    } catch {
      showToast(t('fieldFlow.errors.error'), t('fieldFlow.errors.syncSetup'), 'error', 'sync-setup-error');
    } finally {
      setLoadingSync(false);
    }
  }

  function handleNextStep() {
    if (stepIndex === 0) {
      fieldNextStep(
        t,
        setShowEstimatesModal,
        setShowFinalizeModal,
        setShowSyncErrorModal,
        setShowFieldProcessingModal,
        setShowFinalizeIneligibleAreasModal,
      );
    } else {
      stepForward();
    }
  }

  function getToolTipLabel() {
    if (disableSyncNewPractices) {
      return (
        <>
          <Text>Esse produtor possui práticas novas,</Text>
          <Text>entre em contato com a Rachel</Text>
        </>
      );
    } else if (!enableFinalize && country === 'br') {
      return (
        <>
          <Text>Estimativa não está conectada</Text>
          <Text>a um contrato. Por favor entre</Text>
          <Text>em contato com a equipe de produto</Text>
        </>
      );
    } else {
      if (!overlaps?.length) {
        return (
          <>
            <Text>{t('fieldFlow.mainWizard.Fields.cannotSyncLead.leadsCannot')}</Text>
            <Text>{t('fieldFlow.mainWizard.Fields.cannotSyncLead.syncedBoundaries')}</Text>
            <Text>{t('fieldFlow.mainWizard.Fields.cannotSyncLead.contactAdmin')}</Text>
          </>
        );
      }
      return (
        <>
          <Text>{t('fieldFlow.mainWizard.Fields.cannotSyncOverlap.youCantSynchronize')}</Text>
          <Text>{t('fieldFlow.mainWizard.Fields.cannotSyncOverlap.theFieldsWhen')}</Text>
          <Text>{t('fieldFlow.mainWizard.Fields.cannotSyncOverlap.overlap')}</Text>
        </>
      );
    }
  }

  function showTooltip() {
    const syncLogic = !producerData?.opportunityId || !!overlaps?.length || !enableFinalize || disableSyncNewPractices;
    if (enableAsyncFieldProcessing && country === 'us') {
      return ineligibleAreasFinalized && syncLogic;
    }
    return syncLogic;
  }

  if (currentStep?.hideButton) {
    return null;
  }

  return (
    <>
      {showEstimatesModal && <EstimateErrorModal setShowEstimatesModal={setShowEstimatesModal} />}
      {showSyncErrorModal && (
        <SyncErrorModal
          cancel={() => {
            setShowSyncErrorModal(false);
          }}
        />
      )}
      {showFinalizeModal && <SyncFieldsModal setShowFinalizeModal={setShowFinalizeModal} setShowLoading={setShowLoading} />}
      {showFieldProcessingModal && <FieldProcessingModal />}
      {showFinalizeIneligibleAreasModal && <FinalizeIneligibleAreasModal closeModal={() => setShowFinalizeIneligibleAreasModal(false)} />}
      <Box direction={'row'} gap={'1rem'} align={'center'}>
        {stepIndex > 1 && state !== 'SECOND_SETUP' ? (
          <Box width={'111px'} onClick={() => stepForward(true)}>
            <Text color={'#1457A8'} weight={400}>
              {t('fieldFlow.mainWizard.skip')}
            </Text>
          </Box>
        ) : null}
        <StyledTooltip
          dropProps={{ align: enableFinalize ? { right: 'left' } : { bottom: 'top' } }}
          label={<Box data-cy={'sync-error-hover-message'}>{getToolTipLabel()}</Box>}
          showToolTip={showTooltip()}
        >
          <Button
            data-cy={`confirm${currentStep.name}-button`}
            as={'button'}
            //Grommet Button with disable adds pointerEvent: 'none', breaking the tooltip
            onClick={() => !invalidSteps(stepIndex) && !disableSyncNewPractices && !showLoading && handleNextStep()}
            justify={'center'}
            style={{
              alignItems: 'center',
              minWidth: '140px',
              textAlign: 'center',
              border: 0,
              fontWeight: 'unset',
              padding: '1rem 1.5rem',
              opacity: invalidSteps(stepIndex) || disableSyncNewPractices ? 0.7 : 1,
              background: stepIndex === 0 ? '#FFE137' : '#1B7C3D',
              color: stepIndex === 0 ? '#282828' : '#FFF',
            }}
          >
            {showLoading || loadingSync ? (
              <Box width={'100%'}>
                <Spinner alignSelf={'center'} width={'10px'} height={'10px'} color={'#282828'} />
              </Box>
            ) : (
              <Box width={'100%'} direction={'row'} gap={'8px'} justify={'center'}>
                <Text weight={700} textAlign={'center'} size={'1rem'} style={{ lineHeight: 'unset', whiteSpace: 'nowrap' }}>
                  {t(`fieldFlow.mainWizard.mainButton.${currentStep.name}`)}
                </Text>
              </Box>
            )}
          </Button>
        </StyledTooltip>
      </Box>
    </>
  );
};

export default NextStepButton;
