import { Box } from 'grommet';
import { useMemo } from 'react';
import { DetailsInfo } from '../InformationPanel/components/DetailsInfo';
import { formatDate } from '../../service/common/utils';
import { useTranslation } from 'react-i18next';

interface HerdDetailsProps {
  herd: any;
  versionDisplay?: boolean;
}

export const HerdDetails = ({ herd, versionDisplay }: HerdDetailsProps) => {
  const { t } = useTranslation();
  const changedVariables = useMemo(() => {
    if (!versionDisplay) return [];

    const newDataKeys: string[] = [];
    for (const key in herd.editData.newData) {
      if (key === 'data') {
        for (const dataKey in herd.editData.newData.data) {
          newDataKeys.push(dataKey);
        }
      } else {
        newDataKeys.push(key);
      }
    }
    return newDataKeys;
  }, [herd, versionDisplay]);

  return (
    <Box direction={'row'} align={'center'} gap={'1.125rem'} pad={{ horizontal: '0.75rem', bottom: '0.75rem' }} wrap={true}>
      <DetailsInfo
        label={t('fieldFlow.herdItem.herdInfo.operationType.label')}
        value={herd.operationType}
        valueColor={changedVariables.includes('operationType') ? '#1457A8' : '#282828'}
      />
      <DetailsInfo
        label={t('fieldFlow.herdItem.herdInfo.animalType.label')}
        value={herd.animal}
        valueColor={changedVariables.includes('animal') ? '#1457A8' : '#282828'}
      />
      <DetailsInfo
        label={t('fieldFlow.herdItem.herdInfo.breed.label')}
        value={herd.breed}
        valueColor={changedVariables.includes('breed') ? '#1457A8' : '#282828'}
      />
      <DetailsInfo
        label={t('fieldFlow.herdItem.herdInfo.herdSize')}
        value={herd?.data?.totalSize}
        valueColor={changedVariables.includes('totalSize') ? '#1457A8' : '#282828'}
      />
      <DetailsInfo
        label={t('fieldFlow.herdItem.herdInfo.frameSize.label')}
        value={herd?.data?.frameSize}
        valueColor={changedVariables.includes('frameSize') ? '#1457A8' : '#282828'}
      />
      <DetailsInfo
        label={t('fieldFlow.herdItem.herdInfo.avgWeight')}
        value={herd?.data?.avgWeight}
        valueColor={changedVariables.includes('avgWeight') ? '#1457A8' : '#282828'}
      />
      <DetailsInfo
        label={t('fieldFlow.herdItem.herdInfo.birthingMonth')}
        value={herd?.data?.birthingMonth}
        valueColor={changedVariables.includes('birthingMonth') ? '#1457A8' : '#282828'}
      />
      <DetailsInfo
        label={t('fieldFlow.herdItem.herdInfo.conceptionRate')}
        value={herd?.data?.conceptionRate}
        valueColor={changedVariables.includes('conceptionRate') ? '#1457A8' : '#282828'}
      />
      <DetailsInfo
        label={t('fieldFlow.herdItem.herdInfo.averageAge')}
        value={herd?.data?.averageAge}
        valueColor={changedVariables.includes('averageAge') ? '#1457A8' : '#282828'}
      />
      <DetailsInfo label={t('fieldFlow.ranchOverview.fieldDetails.added')} value={formatDate(herd.startDate)} />
      <DetailsInfo
        label={t('fieldFlow.herdItem.herdInfo.removed')}
        value={herd.endDate ? formatDate(herd.endDate) : t('fieldFlow.ranchOverview.fieldDetails.current')}
      />
      <DetailsInfo label={t('fieldFlow.herdItem.herdInfo.lastChanged')} value={formatDate(herd.editData.date)} />
    </Box>
  );
};
