import { Box, Button, Layer, Spinner, Text } from 'grommet';
import React, { ChangeEvent, useEffect, useState } from 'react';
import checkFileUpload from '../../../../assets/BoundaryTool/check-file-upload.svg';
import errorUpload from '../../../../assets/BoundaryTool/error-upload.svg';
import uploadFile from '../../../../assets/BoundaryTool/upload-file.svg';
import { useMapContext } from '../../service/map/MapContext';
import Field from '../../types/Field';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../service/common/utils';
import { API } from '../../../../service/common/api';
import { FIELD_URL } from '../../../../service/common/env';

enum UploadStatus {
  UPLOADING,
  UPLOADED,
  ERROR,
}

interface FileUpload {
  file?: File;
  name: string;
  size: number;
  uploaded: UploadStatus;
}

export const UploadModal = ({
  journeyId,
  country,
  onBack,
  onContinue,
}: {
  journeyId: string;
  country: string;
  onBack: () => void;
  onContinue: () => void;
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileUpload[]>([]);
  const [height, setHeight] = useState<string>('302px');
  const { addField, centerMapTargeted, setUploadingBoundaries } = useMapContext();

  useEffect(() => {
    let height = '130px';
    if (files.length === 0) {
      height = '302px';
    } else if (files.length === 1) {
      height = '204px';
    }
    setHeight(height);
  }, [files, setHeight]);

  function handleUploadFile(e: ChangeEvent<HTMLInputElement>) {
    setUploadingBoundaries(true);
    const filesSelected = Array.from(e.target.files ?? []).map((file) => {
      return {
        file: file,
        name: file.name,
        size: Math.round((file.size / 1024) * 100) / 100,
        uploaded: UploadStatus.UPLOADING,
      };
    });
    const allFiles = [...files, ...filesSelected];
    setFiles([]);
    setFiles(allFiles);

    const fieldsToAdd: Field[] = [];
    const promises: any[] = [];
    for (const file of filesSelected) {
      promises.push(
        API.post(`${FIELD_URL}/field-service/fields/upload-file-v2?journeyId=` + journeyId, { file: file.file }, 'json', {
          'Content-Type': 'multipart/form-data',
        })
          .then((response) => {
            if (response.data.kmlTreatmentErrors?.iclWithOtherPractices) {
              showToast(t('fieldFlow.errors.error'), `${t('fieldFlow.errors.kmlICLWithOtherPractices')}`, 'error', 'kml-icl-error');
            } else if (response.data.kmlTreatmentErrors?.unrecognizedString?.length) {
              showToast(
                t('fieldFlow.errors.error'),
                `${t('fieldFlow.errors.kmlUnrecognizedString1')}\n\xa0•${response.data.kmlTreatmentErrors.unrecognizedString.join('\n\xa0•')}\n${t(
                  'fieldFlow.errors.kmlUnrecognizedString2',
                )}`,
                'error',
                'kml-treatment-error',
              );
            }
            let fields = response.data.fields;
            fieldsToAdd.push(
              ...fields.map((field: any) => {
                return {
                  ...field.boundaries,
                  id: field.id,
                  properties: {
                    ...field.boundaries.properties,
                    id: undefined,
                    irrigated: field.irrigated || false,
                    name: field.name,
                    country: field.country,
                    state: field.state,
                    county: field.county,
                    area: field.area,
                    areaHectare: field.areaHectare,
                    situation: field.situation ? field.situation : 'OWNED',
                    practiceType: field.practiceType ? field.practiceType : country === 'br' ? 'PASTURE' : 'ROW_CROP',
                    practices: field.practices,
                    paymentOption: field.paymentOption,
                    historicalPractices: field.historicalPractices,
                    legume: field.legume,
                    previousTillage: field.previousTillage,
                    parent: field.id,
                  },
                };
              }),
            );
            setFiles((files) => {
              files.find((fileFind) => fileFind.file === file.file)!.uploaded = UploadStatus.UPLOADED;
              return [...files];
            });
          })
          .catch(() => {
            setFiles((files) => {
              files.find((fileFind) => fileFind.file === file.file)!.uploaded = UploadStatus.ERROR;
              return [...files];
            });
          }),
      );
    }

    Promise.all(promises).then(() => {
      addField(fieldsToAdd);
      centerMapTargeted(fieldsToAdd);
    });
  }

  return (
    <Layer position='center' style={{ borderRadius: '12px' }}>
      <Box width={'430px'} pad={'24px'}>
        <Box
          width={'382px'}
          height={{ min: height, max: height }}
          style={{ border: '1px dashed #808080', borderRadius: '5px' }}
          pad={{ horizontal: '40px', vertical: files.length === 1 ? '13px' : '40px' }}
        >
          <input
            data-cy={'upload-file-input'}
            onChange={(e) => handleUploadFile(e)}
            multiple={true}
            type='file'
            style={{
              cursor: 'pointer',
              opacity: 0.0,
              position: 'absolute',
              top: 24,
              left: 24,
              width: '382px',
              minHeight: height,
              maxHeight: height,
            }}
          />
          {files.length < 2 && <img src={uploadFile} height={112} style={{ marginBottom: '16px' }} alt='draw on map Icon' />}
          <Box direction={'row'} alignSelf={'center'} gap={'4px'}>
            <Text textAlign={'center'} size={'16px'} style={{ lineHeight: '16px', textDecorationLine: 'underline' }} weight={800} color={'#114A8F'}>
              {t('fieldFlow.uploadModal.click')}
            </Text>
            <Text textAlign={'center'} size={'16px'} style={{ lineHeight: '16px' }} weight={800} color={'#282828'}>
              {t('fieldFlow.uploadModal.drag')}
            </Text>
          </Box>
          <Text textAlign={'center'} size={'10px'} style={{ lineHeight: '13px', marginTop: '8px' }} weight={400} color={'#282828'}>
            {t('fieldFlow.uploadModal.supports')}
          </Text>
        </Box>

        <Box overflow={'auto'}>
          {files.map((file, index) => {
            return (
              <Box
                key={`${index}-${file.name}`}
                align={'center'}
                direction={'row'}
                border={{ color: '#CCCCCC', size: '1px' }}
                style={{ borderRadius: '5px', padding: '16px', marginTop: '8px', minHeight: '66px' }}
              >
                {file.uploaded === UploadStatus.UPLOADED ? (
                  <img width={'28px'} src={checkFileUpload} alt={'file uploaded check'} />
                ) : file.uploaded === UploadStatus.UPLOADING ? (
                  <Box width={'32px'}>
                    <Spinner color={'#282828'} size={'1px'} />
                  </Box>
                ) : (
                  <img width={'28px'} src={errorUpload} alt={'file uploaded error'} />
                )}

                <Box direction={'column'} margin={{ left: '16px' }}>
                  <Text size={'16px'} style={{ lineHeight: '16px' }} weight={400} color={'#282828'}>
                    {file.name}
                  </Text>
                  <Text size={'16px'} style={{ lineHeight: '16px' }} weight={400} color={'#808080'}>
                    {file.size} KB
                  </Text>
                </Box>
              </Box>
            );
          })}
        </Box>

        <Button
          data-cy={'upload-continue-button'}
          plain
          onClick={onContinue}
          disabled={files.reduce((p: boolean, c: FileUpload) => p || c.uploaded === UploadStatus.UPLOADING, false)}
          style={{ backgroundColor: '#FFE137', padding: '10px', textAlign: 'center' }}
          margin={{ top: '16px' }}
        >
          <Text size={'16px'} style={{ lineHeight: '16px' }} weight={800} color={'#191408'}>
            {t('fieldFlow.uploadModal.continue')}
          </Text>
        </Button>

        <Button
          data-cy={'upload-back-button'}
          plain
          onClick={onBack}
          margin={{ top: '8px' }}
          style={{ textAlign: 'center', padding: '10px', fontSize: '14px', fontWeight: '800' }}
          label={t('fieldFlow.uploadModal.back')}
        />
      </Box>
    </Layer>
  );
};
