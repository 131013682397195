import { Box, Spinner, Text } from 'grommet';
import { FormPrevious } from 'grommet-icons';
import { useSearchParams } from 'react-router-dom';
import { useMapContext } from '../../service/map/MapContext';
import React, { useMemo } from 'react';
import HerdDropdown from '../InformationPanel/components/Dropdowns/HerdDropdown';

export const OffsiteDetails = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const fieldId = searchParams.get('fieldId');

  const { setState, currentVersion, herds } = useMapContext();

  const filteredHerds = useMemo(
    () =>
      herds.filter((herd) => {
        if (!herd?.rotation || herd?.rotation.length === 0) {
          return herd.fieldId === fieldId;
        }
        return herd?.rotation?.[0]?.fieldId === fieldId;
      }),
    [herds, fieldId],
  );

  return (
    <Box height={'100%'}>
      <Box
        direction={'row'}
        width={'100%'}
        pad={'1rem'}
        margin={'0 0 10px'}
        justify={'between'}
        align={'center'}
        border={{ color: '#CCC', size: '1px', style: 'solid', side: 'bottom' }}
      >
        <Box direction={'row'} gap={'1.25rem'} align={'center'}>
          <FormPrevious
            onClick={() => {
              setState('OVERVIEW');
            }}
            style={{ cursor: 'pointer' }}
            width='9px'
            height='9px'
          />
          <Text weight={900} size={'1rem'}>
            Offsite
          </Text>
        </Box>
      </Box>
      {currentVersion ? (
        <Box pad={'0 1.25rem 0.75rem 1.25rem'} height={'100%'} style={{ maxHeight: '100%' }} gap={'0.75rem'} overflow={'auto'}>
          <HerdDropdown herds={filteredHerds} disableCreation={true} />
        </Box>
      ) : (
        <Box height={'100%'} align={'center'} justify={'center'}>
          <Spinner color={'#FFE137'} size={'0.25rem'} />
        </Box>
      )}
    </Box>
  );
};

export default OffsiteDetails;
