import mapboxgl, { Marker } from 'mapbox-gl';
import { Feature } from '@turf/helpers';
import * as turf from '@turf/turf';
import { MultiPolygon, Polygon } from '@turf/turf';
import './Markers.css';

export interface IElement {
  id: string;
  latitude?: number;
  longitude?: number;
  marker?: Marker;
  fieldId: string;
  paddockId?: string;
  paddockIds?: string[];
  name: string;
}

export class MarkerHelper {
  static entityIcons: { [key: string]: { paddockId: string; coords: number[]; type: 'EVENT' | 'STRUCTURE' | 'HERD' | 'HERD_REMOVED' } } = {};

  static generateNewMarkerOnMap(
    element: IElement,
    paddocks: { [key: string]: Feature[] },
    map: mapboxgl.Map,
    type: 'EVENT' | 'STRUCTURE' | 'HERD' | 'HERD_REMOVED',
    onMarkerClick?: () => void,
    cursorTypeOnHover?: 'pointer' | 'default',
    customIds?: {
      paddockId?: string;
      fieldId?: string;
    },
    updateStructure?: (structure: any) => void,
    postStructure?: (structure: any) => Promise<any>,
  ) {
    if (element.marker) {
      element.marker.remove();
    }

    let paddockId = element.paddockId;
    if (customIds?.paddockId) {
      paddockId = customIds.paddockId;
    } else if (type === 'EVENT' && element.paddockIds?.length) {
      paddockId = element.paddockIds[0];
    }

    const fieldId = customIds?.fieldId ? customIds.fieldId : element.fieldId;

    const paddock = paddocks[fieldId]?.find((paddock) => paddock.id === paddockId);
    if (!paddock) {
      return element;
    }

    let coordinates;
    if (element.latitude && element.longitude && type === 'STRUCTURE') {
      coordinates = {
        latitude: element.latitude,
        longitude: element.longitude,
      };
    } else if (MarkerHelper.entityIcons[element.id] && MarkerHelper.entityIcons[element.id].paddockId === paddockId) {
      coordinates = {
        latitude: MarkerHelper.entityIcons[element.id].coords[1],
        longitude: MarkerHelper.entityIcons[element.id].coords[0],
      };
    } else {
      let otherEventSamePaddock;
      if (type === 'EVENT') {
        const entityList = Object.keys(MarkerHelper.entityIcons)
          .map((entityId) => MarkerHelper.entityIcons[entityId])
          .filter((entity) => entity.type === 'EVENT');
        otherEventSamePaddock = entityList.find((entity) => entity.paddockId === paddockId);
      }

      if (otherEventSamePaddock) {
        coordinates = {
          latitude: otherEventSamePaddock.coords[1],
          longitude: otherEventSamePaddock.coords[0],
        };
        MarkerHelper.entityIcons = {
          ...MarkerHelper.entityIcons,
          [element.id]: {
            paddockId: paddockId ?? '',
            coords: otherEventSamePaddock.coords,
            type,
          },
        };
      } else {
        const center = MarkerHelper.generateRandomPositionInPolygon(paddock);
        coordinates = {
          latitude: center[1],
          longitude: center[0],
        };
        MarkerHelper.entityIcons = {
          ...MarkerHelper.entityIcons,
          [element.id]: {
            paddockId: paddockId ?? '',
            coords: [center[0], center[1]],
            type,
          },
        };
      }
    }

    const el = document.createElement('div');
    el.className = type.toLowerCase();

    element.marker = new Marker({ element: el, draggable: type === 'STRUCTURE' }).setLngLat({
      lng: coordinates.longitude,
      lat: coordinates.latitude,
    });

    MarkerHelper.addCursorTypeControl(element, cursorTypeOnHover);

    if (onMarkerClick) {
      const markerDiv = element.marker.getElement();
      markerDiv.addEventListener('click', onMarkerClick);
    }

    if (type === 'STRUCTURE') {
      element.latitude = coordinates.latitude;
      element.longitude = coordinates.longitude;
      element.marker.on('dragend', () => {
        MarkerHelper.onStructureDragEnd(element, element.marker as Marker, paddock, updateStructure!, postStructure!);
      });
    } else if (type === 'HERD' || type === 'HERD_REMOVED') {
      MarkerHelper.addPopUpOnHover(element);
    }

    return element;
  }

  static addPopUpOnHover(element: IElement) {
    const markerDiv = element.marker!.getElement();
    markerDiv.addEventListener('mouseover', () => {
      element.marker!.togglePopup();
    });
    markerDiv.addEventListener('mouseleave', () => {
      element.marker!.togglePopup();
    });

    const popup = new mapboxgl.Popup({ offset: 25 }).setText(element.name);
    element.marker!.setPopup(popup);
  }

  static addCursorTypeControl(element: IElement, cursorType: 'pointer' | 'default' = 'pointer') {
    const markerDiv = element.marker!.getElement();
    markerDiv.addEventListener('mouseover', () => {
      markerDiv.style.cursor = cursorType;
    });
  }

  static generateRandomPositionInPolygon(polygon: Feature) {
    const bbox = turf.bbox(polygon);

    let point;
    do {
      point = turf.randomPosition(bbox);
    } while (!turf.booleanPointInPolygon(point, polygon as Feature<Polygon | MultiPolygon>));

    return point;
  }

  static onStructureDragEnd(
    structure: any,
    marker: Marker,
    paddock: Feature,
    updateStructure: (structure: any) => void,
    postStructure: (structure: any) => Promise<any> | void,
  ) {
    const lngLat = marker.getLngLat();
    if (!turf.booleanPointInPolygon(lngLat.toArray(), paddock as Feature<Polygon | MultiPolygon>)) {
      marker.setLngLat([structure.longitude, structure.latitude]);
      return;
    }
    structure.latitude = lngLat.lat;
    structure.longitude = lngLat.lng;
    updateStructure(structure);
    if (typeof structure.id !== 'number') return postStructure(structure);
  }
}
