import { debounce } from 'lodash';
import AgoToast from '../../components/Toast/AgoToast';
import * as EstimateService from '../../service/estimate/estimate.service';
import { CreditEstimate, IEstimate, Practice, PracticeTypes, Scenario } from '../../service/estimate/interfaces';
import store from '../index';
import { ContractService } from '../../service/contract/contract.service';

export const callGetEstimateCalculationsAction = (estimate?: IEstimate) => {
  if (estimate?.id) {
    return EstimateService.getEstimateById(estimate.id);
  }
  throw Error(`Estimate doesn't exist`);
};

export const callGetEstimateService = (estimateId: string, journeyId: string, usCometV2: boolean) => {
  if (journeyId && estimateId) {
    return EstimateService.getEstimateById(estimateId);
  }
  return EstimateService.getStandaloneEstimate(usCometV2).catch((e) => {
    if (e.response?.status === 404) {
      console.error('No stand alone estimate in progress');
      return null;
    }
  });
};

export const getEstimateAction: any = (estimateId: string, journeyId: string, usCometV2: boolean) => (dispatch: any) => {
  if (journeyId && !estimateId) {
    return;
  }

  dispatch({
    type: 'GET_ESTIMATE_REQUESTED',
    payload: {
      estimateId,
      journeyId,
    },
  });

  return callGetEstimateService(estimateId, journeyId, usCometV2)
    .then((res: any) => {
      // journeyId.length > 12 means it is a growerId
      if (res && !res.currentEstimate.growerId && journeyId && journeyId.length > 12) {
        res.currentEstimate.growerId = journeyId;
      } else if (res && !res.currentEstimate.journeyId && journeyId) {
        res.currentEstimate.journeyId = journeyId;
      }
      dispatch({
        type: 'GET_ESTIMATE_SUCCEEDED',
        payload: res,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'GET_ESTIMATE_ERROR',
        payload: err,
      });
      AgoToast.showToast({
        title: 'Error loading Estimates',
        message: err.message,
        type: 'error',
        toastId: 'lead-request',
      });
    });
};

export const getAvailablePractices: any = (contractVersionId, usStackTillageWithLegumeAddition: boolean) => (dispatch: any) => {
  dispatch({
    type: 'AVAILABLE_PRACTICES_REQUESTED',
  });
  return Promise.all([EstimateService.getAvailablePractices(), ContractService.getContractVersionById(contractVersionId)])
    .then((data: any) => {
      const availablePractices = data[0];
      const contractVersion = data[1];
      const contractPractices = contractVersion.availablePractices.split(',');
      const practices = availablePractices
        .filter((i) => contractPractices.indexOf(i.shortCode) >= 0)
        .map((res) => {
          if (res.name === 'Nitrogen reduction' || (res.name === 'Reduced Tillage' && usStackTillageWithLegumeAddition)) {
            return {
              ...res,
              legacy: 'BOTH',
            };
          }
          return res;
        });
      dispatch({
        type: 'AVAILABLE_PRACTICES_SUCCEEDED',
        payload: {
          practices,
          country: contractVersion.country,
        },
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'AVAILABLE_PRACTICES_ERROR',
        payload: err,
      });
      AgoToast.showToast({
        title: 'Error loading Available Practices',
        message: err.message,
        type: 'error',
        toastId: 'practices-request',
      });
    });
};

export const getUpdatePracticeAction: any = (fieldId: string, practiceId: string, practice: any) => (dispatch: any) => {
  dispatch({
    type: 'UPDATE_PRACTICE_REQUESTED',
    payload: {
      fieldId,
      practiceId,
      practice,
    },
  });
  return calculateAndSaveAction(dispatch);
};

export const getSaveFieldInfoAction: any = (field: any) => (dispatch: any) => {
  dispatch({
    type: 'SAVE_FIELD_REQUESTED',
    payload: {
      field,
    },
  });
  return calculateAndSaveAction(dispatch);
};
export const getDeleteFieldAction: any = (field: any) => (dispatch: any) => {
  dispatch({
    type: 'DELETE_FIELD_REQUESTED',
    payload: {
      field,
    },
  });
  return calculateAndSaveAction(dispatch);
};

export const getDuplicateFieldAction: any = (field: any) => (dispatch: any) => {
  dispatch({
    type: 'DUPLICATE_FIELD_REQUESTED',
    payload: {
      field,
    },
  });
};
export const getDuplicateFieldProcessedAction: any = (field: any) => (dispatch: any) => {
  dispatch({
    type: 'DUPLICATE_FIELD_PROCESSED',
  });
};

const calculateAndSaveAction = debounce((dispatch: any) => {
  return calculateAction(dispatch).then((result: any) => {
    return saveScenariosAction(result.calculatorResultCombined.updatedScenarios)(dispatch);
  });
}, 800);

export const clearAllFieldAction: any = () => (dispatch: any) => {
  dispatch({
    type: 'CLEAR_FIELD_REQUESTED',
  });
  return calculateAndSaveAction(dispatch);
};

const calculateAction: any = (dispatch: any) => {
  const state: any = store.getState();
  const scenarios: Scenario[] = state.estimate.fields;
  const estimateId: string = state.estimate.estimateFromDb.id;
  dispatch({
    type: 'CALCULATE_REQUESTED',
    payload: { scenarios, estimateId },
  });
  return EstimateService.calculateEstimate(scenarios, state.estimate.estimateFromDb.contractVersionId, estimateId)
    .then((results) => {
      // If carbon is null, then the calculator was not able to find data for a county
      if (results?.calculatorResultA?.carbon?.totalCarbon === null && results?.calculatorResultB?.carbon?.totalCarbon === null) {
        dispatch({
          type: 'CALCULATE_FAILED',
          payload: results,
        });
        return results;
      }
      dispatch({
        type: 'CALCULATE_SUCCEEDED',
        payload: results,
      });
      return results;
    })
    .catch((e) => {
      // If status code is 404, then the calculator was not able to find comet data for a county
      if (e?.response?.status === 404) {
        dispatch({
          type: 'CALCULATE_FAILED',
        });
      }
      throw e;
    });
};

const saveScenariosAction: any = (scenarios: any) => async (dispatch: any) => {
  const currentRequestId = Math.random();
  const state: any = store.getState();
  const estimateFromDb = state.estimate.estimateFromDb;
  //Only add practices that are available in current contract
  const availablePracticesInContract = state.estimate.availablePractices;
  //Avoid saving estimate before get is finished, prevents creating multiple drafts
  if (state.estimate.estimateState.lifeCycle !== 'SUCCEEDED') return;

  scenarios.forEach((field: any) => {
    field.practices = field.practices.filter(
      (practice: Practice) => !!availablePracticesInContract.find((p) => p.readableCode === practice.readableCode),
    );
    field.practices.forEach((practice: Practice) => {
      delete practice.readableCode;
    });
  });
  const estimateToSave = {
    ...estimateFromDb,
    data: scenarios,
  };
  dispatch({
    type: 'SAVE_SCENARIOS_REQUESTED',
    payload: { ...estimateToSave, currentRequestId },
  });
  return EstimateService.saveEstimate(estimateToSave)
    .then((res: any) => {
      dispatch({
        type: 'SAVE_SCENARIOS_SUCCEEDED',
        payload: { ...res, currentRequestId },
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'SAVE_SCENARIOS_ERROR',
        payload: err,
      });
      AgoToast.showToast({
        title: 'Error Saving Scenarios',
        message: err.message,
        type: 'error',
        toastId: 'lead-request',
      });
    });
};

export const updateEstimateNameAction: any = (estimateName: any) => async (dispatch: any) => {
  const state: any = store.getState();
  const estimateFromDb = state.estimate.estimateFromDb;
  const estimateToSave = {
    data: [],
    ...estimateFromDb,
    name: estimateName,
  };
  dispatch({
    type: 'UPDATE_ESTIMATE_NAME_REQUESTED',
    payload: estimateName, //Assign name to EstimateDB object, so it doesn't get overwritten with race condition
  });
  return EstimateService.saveEstimate(estimateToSave)
    .then((res: any) => {
      dispatch({
        type: 'UPDATE_ESTIMATE_NAME_SUCCEEDED',
        payload: res,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'UPDATE_ESTIMATE_NAME_ERROR',
        payload: err,
      });
      AgoToast.showToast({
        title: 'Error Saving Estimate Name',
        message: err.message,
        type: 'error',
        toastId: 'lead-request',
      });
    });
};
export const updateEstimateNotesAction: any = (notes: any) => async (dispatch: any) => {
  const state: any = store.getState();
  const estimateFromDb = state.estimate.estimateFromDb;
  const estimateToSave = {
    data: [],
    ...estimateFromDb,
    notes,
  };
  dispatch({
    type: 'UPDATE_ESTIMATE_NOTES_REQUESTED',
    payload: notes,
  });
  debouncedApiRequest(dispatch, estimateToSave);
};
const debouncedApiRequest = debounce(
  (dispatch, estimateToSave) =>
    EstimateService.saveEstimate(estimateToSave)
      .then((res: any) => {
        dispatch({
          type: 'UPDATE_ESTIMATE_NOTES_SUCCEEDED',
          payload: res,
        });
      })
      .catch((err: any) => {
        dispatch({
          type: 'UPDATE_ESTIMATE_NOTES_ERROR',
          payload: err,
        });
        if (err.response.status !== 412) {
          AgoToast.showToast({
            title: 'Error Saving Estimates Notes',
            message: err.message,
            type: 'error',
            toastId: 'lead-request',
          });
        }
      }),
  500,
);
export const updateSyncedEstimateAction: any = (oldSyncedEstimate: any) => async (dispatch: any) => {
  const state: any = store.getState();
  const estimateFromDb = state.estimate.estimateFromDb;
  const estimateToSave = {
    ...estimateFromDb,
    sync: true,
  };
  dispatch({
    type: 'UPDATE_ESTIMATE_SYNC_REQUESTED',
  });
  await EstimateService.saveEstimate({ ...oldSyncedEstimate, sync: false });
  return EstimateService.saveEstimate(estimateToSave)
    .then((res: any) => {
      dispatch({
        type: 'UPDATE_ESTIMATE_SYNC_SUCCEEDED',
        payload: res,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'UPDATE_ESTIMATE_SYNC_ERROR',
        payload: err,
      });
      AgoToast.showToast({
        title: 'Error Syncing Estimates',
        message: err.message,
        type: 'error',
        toastId: 'sync-request',
      });
    });
};

export const clearEstimateStore: any = () => async (dispatch: any) => {
  dispatch({
    type: 'ESTIMATE_PAGE_DISMOUNTED',
  });
  return Promise.resolve();
};

export const saveEstimateAction: any = (estimate: any) => async (dispatch: any) => {
  dispatch({
    type: 'SAVE_ESTIMATE_REQUESTED',
  });
  return EstimateService.saveEstimate(estimate)
    .then((res: any) => {
      dispatch({
        type: 'SAVE_ESTIMATE_SUCCEEDED',
        payload: res,
      });
      return res;
    })
    .catch((err: any) => {
      dispatch({
        type: 'SAVE_ESTIMATE_ERROR',
        payload: err,
      });
      AgoToast.showToast({
        title: 'Error Saving Estimates',
        message: err.message,
        type: 'error',
        toastId: 'estimate-save-request',
      });
    });
};

export const applyPracticesToAllScenariosAction: any = (practices: Practice[], practiceType: PracticeTypes) => (dispatch: any) => {
  const state: any = store.getState();
  const scenarios: Scenario[] = state.estimate.fields;
  const updatedScenarios = scenarios.map((scenario: Scenario) => {
    if (scenario.practiceType === practiceType) {
      return {
        ...scenario,
        practices: practices.map((practice) => ({ ...practice })),
      };
    }
    return scenario;
  });

  dispatch({
    type: 'APPLY_PRACTICES_TO_ALL_SCENARIOS_REQUESTED',
    payload: updatedScenarios,
  });

  return calculateAndSaveAction(dispatch);
};

export const applyCreditEstimateToAllScenariosAction: any = (creditEstimate: CreditEstimate) => (dispatch: any) => {
  const state: any = store.getState();
  const scenarios: Scenario[] = state.estimate.fields;
  const updatedScenarios = scenarios.map((scenario: Scenario) => {
    return {
      ...scenario,
      details: {
        ...scenario.details,
        creditEstimate: {
          ...creditEstimate,
        },
      },
    };
  });
  dispatch({
    type: 'APPLY_CREDIT_ESTIMATE_TO_ALL_SCENARIOS_REQUESTED',
    payload: updatedScenarios,
  });
  return calculateAndSaveAction(dispatch);
};

export const copyFieldsFromBoundaryAction: any = (fields: Scenario[]) => (dispatch: any) => {
  dispatch({
    type: 'COPY_FIELDS_FROM_BOUNDARY_REQUESTED',
    payload: fields,
  });
  return calculateAndSaveAction(dispatch);
};

export const getBrazilSpeciesAction: any = () => (dispatch: any) => {
  dispatch({
    type: 'GET_BRAZIL_SPECIES_REQUESTED',
  });

  return EstimateService.getBrazilSpecies().then((species) => {
    dispatch({
      type: 'GET_BRAZIL_SPECIES_SUCCEEDED',
      payload: species,
    });
  });
};

export const getICLFactorAction: any = () => (dispatch: any) => {
  dispatch({
    type: 'GET_ICL_FACTOR_REQUESTED',
  });

  return EstimateService.getICLFactor().then((iclFactor) => {
    dispatch({
      type: 'GET_ICL_FACTOR_SUCCEEDED',
      payload: iclFactor,
    });
  });
};
