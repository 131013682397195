import axios, { ResponseType } from 'axios';
import store from '../../redux';
import { getAccessTokenAction } from '../../redux/actions/context';
import AgoToast from '../../components/Toast/AgoToast';

const getAccessToken = () => {
  const state = store.getState();
  return state.context.accessToken;
};

const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${getAccessToken()}`,
      ...config.headers,
    };
    return config;
  },
  (error) => Promise.reject(error),
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const state = store.getState();
    const getAccessTokenFn = state.context.getAccessTokenFn;

    const originalRequest = error.config;
    if ((error.response.status === 403 || error.response.status === 401) && !originalRequest._retry) {
      originalRequest._retry = true;
      await store.dispatch(getAccessTokenAction(getAccessTokenFn));
      axios.defaults.headers.common['Authorization'] = `Bearer ${getAccessToken()}`;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  },
);

export const triggerDownload = (data: any, fileName: string, fileExtension?: string) => {
  const blob = new Blob([data], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('download', `${fileName}${!!fileExtension ? '.' + fileExtension : ''}`);
  a.setAttribute('href', url);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const downloadSignedUrl = async (signedUrl, fileName) => {
  try {
    const response = await axios.get(signedUrl, {
      responseType: 'blob',
      transformRequest: (data, headers) => {
        if (headers) {
          delete headers['Authorization'];
          delete headers.common['Authorization'];
        }
        return data;
      },
    });
    triggerDownload(response.data, fileName);
  } catch (e) {
    AgoToast.showToast({
      title: 'Error',
      message: `We could not download the requested file for this producer. Please contact the Product team`,
      type: 'error',
      toastId: `download-${fileName}-error`,
    });
  }
};

export const getStaticFilesBaseUrl = () => {
  return process.env.REACT_APP_STATIC_FILES_ENDPOINT;
};

const get = async (url: string, params?: any) => {
  return axiosApiInstance.get(url, {
    params,
  });
};

const patch = async (url: string, bodyData: any) => {
  return axiosApiInstance.patch(url, bodyData);
};

const put = async (url: string, bodyData: any) => {
  return axiosApiInstance.put(url, bodyData);
};

const post = async (url: string, bodyData: any, responseType?: ResponseType, headers?: any) => {
  return axiosApiInstance.post(url, bodyData, {
    responseType,
    headers,
  });
};

const deleteMethod = async (url: string) => {
  return axiosApiInstance.delete(url);
};

const downloadFile = async (url: string, params?: any) => {
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
    responseType: 'blob',
    params,
  });
};

const downloadFileOrJson = async (url: string, params?: any) => {
  const response = await axiosApiInstance.post(url, params, {
    responseType: 'blob',
  });
  return response;
};

export const API = {
  getAccessToken,
  post,
  get,
  patch,
  put,
  delete: deleteMethod,
  downloadFile,
  downloadFileOrJson,
};
