import { FieldLayout } from '../../../../types/FieldLayout';
import { formatDate } from '../../../../service/common/utils';
import { Box, Image, Text } from 'grommet';
import arrowLong from '../../../../../../assets/BoundaryTool/arrow-long.svg';
import { useTranslation } from 'react-i18next';

const DateRangeList = (props: { dateRanges: FieldLayout[] }) => {
  const { t } = useTranslation();
  const createdDateRanges = props.dateRanges.map((item) => [
    item.name,
    formatDate(item.startDate),
    item.endDate ? formatDate(item.endDate) : 'Current',
  ]);
  return (
    <>
      <Text size='26px' color='#282828' weight={800} style={{ lineHeight: '1.7rem' }}>
        {t('fieldFlow.dateRangeList.title')}
      </Text>
      <Text margin={'0.5rem 0'}>{t('fieldFlow.dateRangeList.versionList')} </Text>
      <Box
        margin={'0 0 0.75rem 0'}
        round={'5px'}
        style={{ maxHeight: '28vh', minHeight: 'unset' }}
        overflow={'auto'}
        border={{ color: '#282828', size: '1px' }}
        align={'center'}
        pad={'1rem 2.25rem'}
        gap={'1rem'}
      >
        {createdDateRanges.map((dateRange, index) => {
          return (
            <Box
              key={`created-date-ranges-${index}`}
              style={{ minHeight: 'unset' }}
              width={'100%'}
              direction={'row'}
              align={'between'}
              justify={'between'}
            >
              <Text style={{ width: '30%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} size={'1rem'} weight={700}>
                {dateRange[0]}
              </Text>
              <Text style={{ width: '25%' }} size={'1rem'} weight={700}>
                {dateRange[1]}
              </Text>
              <Image src={arrowLong} width={'20px'} />
              <Text style={{ width: '30%', textAlign: 'center' }} size={'1rem'} weight={700}>
                {dateRange[2]}
              </Text>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default DateRangeList;
