import React, { createContext, MutableRefObject, useContext } from 'react';
import { useMapService } from './MapService';
import { Feature } from '@turf/helpers';
import DrawControl from '../../components/Map/DrawControl';
import mapboxgl from 'mapbox-gl';
import Field from '../../types/Field';
import { EditData } from '../../types/EditChanges';
import { AxiosResponse } from 'axios';
import { ShapefileStatus } from '../../types/Shapefiles';
import { SyncErrors } from '../FieldService';
import { User } from 'auth0';
import { EnumProcessingStatusType } from '../common/types';
import { Journey } from '../../../customer-profile/types/GrowerService';

export type states = 'OVERVIEW' | 'OVERVIEW_V2' | 'DETAILS' | 'FIRST_SETUP' | 'SECOND_SETUP';

//TODO: Remove unused/deleted function and constants from this Type
export interface MapContextType {
  journey: Journey | undefined;
  shapefileStatus: ShapefileStatus;
  map: undefined | mapboxgl.Map;
  setMap: (map: undefined | mapboxgl.Map) => void;
  centerMap: () => void;
  centerMapTargeted: (fields: Array<Feature>) => void;
  hasBoundaries: () => boolean;

  loading: boolean;
  uploadingBoundaries: boolean;
  setUploadingBoundaries: (isUploading: boolean) => void;
  uploadingDrawnField: boolean;
  setUploadingDrawnField: (isUploading: boolean) => void;
  state: states;
  setState: (state: states) => void;
  companyName: string;
  producerData: any;
  country: string;

  mode: string;
  setMode: (mode: string) => void;
  restartDraw: () => void;
  setDraw: (draw: DrawControl | null) => void;
  finishedDrawingField: boolean;
  finishedDrawingPaddock: boolean;
  willLoseProgressOnExit: boolean;
  updatedFieldBoundary: MutableRefObject<boolean>;
  setWillLoseProgressOnExit: (value: ((prevState: boolean) => boolean) | boolean) => void;

  estimate: any;
  enableFinalize: boolean;
  contractPaymentOption?: string;
  getEstimate: () => Promise<any>;
  calculateCarbonEstimate: () => Promise<any>;

  currentVersion: any;
  setCurrentVersion: (version: any) => void;
  changingVersion: boolean;

  showConfirmationModal: boolean;
  setShowConfirmationModal: (value: ((prevState: boolean) => boolean) | boolean) => void;
  showUploadModal: boolean;
  setShowUploadModal: (value: ((prevState: boolean) => boolean) | boolean) => void;
  restartPaddockBoundary: () => void;

  fields: Field[];
  getFields: () => Promise<void | { fields: Field[]; paddocks: { [key: string]: Feature[] } }>;
  addField: (field?: Field | Field[]) => void;
  updateField: (field?: Field) => void;
  removeField: (field: Field) => void;
  removeAllFields: () => void;
  focusField: (field?: Field) => void;
  startSplittingField: (field: Field | string) => void;
  startEditingField: (field: Field | string) => void;
  deleteField: Field | undefined;
  setDeleteField: (field: Field | undefined) => void;
  deleteAllFields: boolean;
  setDeleteAllFields: (deleteAllFields: boolean) => void;
  saveFields: () => Promise<void>;
  fieldsIntersecting: boolean;
  fieldsDrawingInvalid: boolean;
  paddocksInvalid: boolean;
  getFieldCenter: (field: Field) => void;
  getFieldsLocations: () => Array<string>;
  totalFieldsAcreage: string;
  getFieldsPractices: () => Array<string>;
  upsertShapefile: (status: ShapefileStatus, estimatedCarbon?: number) => Promise<AxiosResponse>;
  getSyncAvailability: () => Promise<SyncErrors>;
  getSyncAvailabilityEstimate: () => boolean;
  syncErrors: SyncErrors;
  setSyncErrors: (syncErrors: SyncErrors) => void;
  getPracticeData: (field: Field[]) => any;
  notifyUpdate: (producerName: string, urlProducerProfile: string, updatedBy: User) => Promise<AxiosResponse<any> | void>;

  layouts: any[] | undefined;

  paddocks: { [key: string]: Feature[] };
  setPaddocks: (paddocks: { [key: string]: Feature[] }) => void;
  setDrawnPaddocksForField: (field: Field) => void;
  updatePaddock: (feature: Feature) => void;
  setNotSavedPaddockVersion: () => void;
  saveVersion: () => Promise<void>;
  getLastPossibleVersionEndDate: () => Promise<any>;
  endCurrentVersion: (date: Date) => void;
  restartPaddock: (field: Feature) => void;
  removeAllMarkers: () => void;
  onModeChange: (event: any) => void;
  onDrawUpdate: (event: any) => void;

  herds: any[];
  herdsInvalid: boolean;
  updateHerdData: (herds: any[]) => void;
  filterHerdMarkers: (herds?: Record<string, any>[] | Record<string, any>) => void;
  handleHerdMarkers: (herd: any) => Record<string, any>;
  addHerd: (fieldId?: string, fieldName?: string) => void;
  updateHerd: (herd: any) => void;
  duplicateHerd: (herd: any) => void;
  removeHerd: (herd: any) => void;
  saveHerd: (herd: any) => Promise<void>;
  saveHerds: () => Promise<void>;
  saveMoveEvent: (moveEvent: any, herd: any) => Promise<void>;
  removeSavedHerd: (moveEvent: any, herd: any) => Promise<void>;
  getMoveLocations: (
    excludePaddocks?: string[],
  ) => { locationName: string; paddockName: string; fieldName: string; fieldId: string; paddockId: string }[];
  herdRemovedThisVersion: (herd: any) => boolean;
  getOffsiteHerds: () => any[];
  getLastMovementDate: (herd: any) => Promise<Date>;
  getHerdHistory: (herd: any) => Promise<any[]>;
  getLastChangeDate: (herd: any) => Promise<{ lastDate: Date }>;
  getLastHerdDate: (herd: any) => Promise<{ lastDate: Date }>;
  editHerd: (herdId: string, editChanges: EditData) => void;
  clearHerds: () => void;

  events: any[];
  addEvent: (fieldId?: string, fieldName?: string) => void;
  updateEvent: (herd: any) => void;
  removeEvent: (herd: any) => void;
  saveEvent: (event: any) => Promise<void>;
  saveEvents: () => Promise<void>;

  structures: any[];
  structuresInvalid: boolean;
  setStructures: (structures: any[]) => void;
  addStructure: (fieldId?: string) => void;
  updateStructure: (structure: any) => void;
  removeStructure: (structure: any) => void;
  saveStructure: (structure: any) => Promise<void>;
  saveStructures: () => Promise<void>;

  wizardStep: 'PADDOCK' | 'FIELD' | undefined;
  setWizardStep: (step: 'PADDOCK' | 'FIELD' | undefined) => void;
  invalidSteps: (stepIndex: number) => boolean;

  practicesOnContract: any[];
  nearFields: any;
  overlaps: any;
  showCloserFields: boolean;
  toggleShowNearFields: (toggleValue: boolean) => void;

  changeFieldEnrollmentStatus: (field: Field, status: boolean, hidden?: boolean) => void;
  toggleHideFields: (hidden: boolean) => void;

  processingStatus: EnumProcessingStatusType;
  startFieldProcessing: () => Promise<void>;
  getFieldProcessingStatus: (onProcessed?: () => void) => Promise<void>;
  finalizeFieldProcessing: (choices: Array<string>) => Promise<void>;
  ineligibleAreasFinalized: boolean;
}

const MapContext = createContext<MapContextType>({} as MapContextType);
export const useMapContext = () => useContext(MapContext);

export function MapContextProvider({ children }: { children: React.ReactNode }) {
  const mapService = useMapService();
  return <MapContext.Provider value={mapService}>{children}</MapContext.Provider>;
}
