import { User } from '@auth0/auth0-react';
import { User as UserObj } from './../reducers/user-reducer';
import { UserService } from '../../service/common/user.service';
import AgoToast from '../../components/Toast/AgoToast';

export const getUser: any = (user: User) => (dispatch: any) => {
  dispatch({
    type: 'GET_USER_REQUESTED',
  });
  const userMetadata = user['https://api.pa.agoro.comuser_metadata'];
  const appMetadata = user['https://api.pa.agoro.comapp_metadata'];
  const roles = user['https://api.pa.agoro.com/roles'];
  const firstName = userMetadata?.firstName || '';
  const lastName = userMetadata?.lastName || '';
  const userParsed: UserObj = {
    id: user.sub || '',
    email: user.email || '',
    country: user.country || 'US',
    fullName: `${firstName} ${lastName}`,
    firstName: firstName,
    lastName: lastName,
    phoneNumber: userMetadata?.phoneNumber || '',
    channelPartnerId: appMetadata?.channelPartnerId || '',
    partnerType: appMetadata?.partnerType || '',
    partnerTier: appMetadata?.partnerTier || null,
    language: user.country === 'BR' ? 'pt-BR' : 'en',
    roles: roles || [],
  };
  return dispatch({
    type: 'GET_USER_SUCCEEDED',
    payload: userParsed,
  });
};

export const getUserPartnerContracts: any = () => (dispatch: any) => {
  dispatch({
    type: 'GET_USER_PARTNER_CONTRACTS_REQUESTED',
  });
  return UserService.getContractsFromPartner()
    .then((contracts: any) => {
      dispatch({
        type: 'GET_USER_PARTNER_CONTRACTS_SUCCEEDED',
        payload: contracts,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'GET_USER_PARTNER_CONTRACTS_ERROR',
        payload: err,
      });
    });
};

export const getUserListAction: any = () => (dispatch: any) => {
  dispatch({
    type: 'GET_USER_LIST_REQUESTED',
  });
  return UserService.getUsersFromAuth()
    .then((users: any) => {
      dispatch({
        type: 'GET_USER_LIST_SUCCEEDED',
        payload: users,
      });
    })
    .catch((err: any) => {
      dispatch({
        type: 'GET_USER_LIST_ERROR',
        payload: err,
      });
      AgoToast.showToast({
        title: 'User error',
        message: `You don't have permission to access this resource`,
        type: 'error',
        toastId: 'access-token-error',
      });
    });
};
