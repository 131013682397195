import { createReducer } from '@reduxjs/toolkit';
import { Evidence } from '../../pages/customer-profile/components/Tabs/EvidenceTab/interfaces';

const initialState: any = {
  allEvidences: [],
  evidences: [],
  journeys: [],
  evidencesState: { lifeCycle: 'NONE' },
  journeysState: { lifeCycle: 'NONE' },
  evidenceTags: [],
  practicesFilter: [],
  yearsFilter: [],
};

const filterEvidences = (allEvidences: Evidence[], practices: string[], years: string[]) => {
  if (!practices.length && !years.length) {
    return allEvidences;
  }

  const filteredEvents = allEvidences.filter((ev: Evidence) => {
    const practiceTags = ev.tags?.filter((t) => t.reference !== 'YEAR_TAG');
    const yearTags = ev.tags?.filter((t) => t.reference === 'YEAR_TAG');

    const pracIncluded = !practices.length || practiceTags?.some((tag) => practices.indexOf(tag.id) !== -1);
    const yearIncluded = !years.length || yearTags?.some((tag) => years.indexOf(tag.id) !== -1);

    return pracIncluded && yearIncluded;
  });

  return filteredEvents;
};

const reducer = createReducer(initialState, {
  GET_EVIDENCES_REQUESTED(state) {
    state.evidencesState.lifeCycle = 'PENDING';
  },
  GET_EVIDENCES_SUCCEEDED(state, action) {
    state.evidencesState.lifeCycle = 'SUCCESS';
    state.evidences = action.payload;
    state.allEvidences = action.payload;
  },
  GET_EVIDENCES_ERRORED(state, action) {
    state.evidencesState.lifeCycle = 'ERROR';
    state.evidencesState.error = action.payload;
    state.evidences = [];
    state.allEvidences = [];
  },
  GET_EVIDENCE_TAGS_SUCCEEDED(state, action) {
    state.evidenceTags = action.payload;
  },
  GET_EVIDENCE_TAGS_ERRORED(state) {
    state.evidences = [];
    state.allEvidences = [];
  },
  GET_JOURNEYS_REQUESTED(state) {
    state.journeysState.lifeCycle = 'PENDING';
  },
  GET_JOURNEYS_SUCCEEDED(state, action) {
    state.journeysState.lifeCycle = 'SUCCESS';
    state.journeys = action.payload;
  },
  GET_JOURNEYS_ERRORED(state, action) {
    state.journeysState.lifeCycle = 'ERROR';
    state.journeysState.error = action.payload;
    state.journeys = [];
  },
  UPLOAD_EVIDENCES_REQUESTED(state) {
    state.evidencesState.lifeCycle = 'PENDING';
  },
  EVIDENCES_CREATED_SUCCEEDED(state, action) {
    const newEvidencesList = state.allEvidences.concat(action.payload);
    const filteredEvidences = filterEvidences(newEvidencesList, state.practicesFilter, state.yearsFilter);

    state.evidences = filteredEvidences;
    state.allEvidences = newEvidencesList;

    state.evidencesState.lifeCycle = 'SUCCESS';
  },
  EVIDENCES_CREATED_ERROR(state) {
    state.evidencesState.lifeCycle = 'ERROR';
  },
  DELETE_EVIDENCE_REQUESTED(state) {
    state.evidencesState.lifeCycle = 'PENDING';
  },
  DELETE_EVIDENCE_SUCCEEDED(state, action) {
    const deleted = action.payload;
    const newEvidencesList = state.allEvidences.filter((ev) => ev.id !== deleted);

    const filteredEvidences = filterEvidences(newEvidencesList, state.practicesFilter, state.yearsFilter);

    state.evidences = filteredEvidences;
    state.allEvidences = newEvidencesList;

    state.evidencesState.lifeCycle = 'SUCCESS';
  },
  DELETE_EVIDENCE_ERRORED(state) {
    state.evidencesState.lifeCycle = 'ERROR';
  },
  UPDATE_EVIDENCE_REQUESTED(state, action) {
    state.evidencesState.lifeCycle = 'PENDING';
  },
  UPDATE_EVIDENCE_SUCCEEDED(state, action) {
    const updatedList = state.evidences.map((ev) => {
      return ev.id === action.payload.id ? action.payload : ev;
    });

    state.evidences = updatedList;
    state.allEvidences = updatedList;
    state.evidencesState.lifeCycle = 'SUCCESS';
  },
  UPDATE_EVIDENCE_ERRORED(state) {
    state.evidencesState.lifeCycle = 'ERROR';
  },
  BULK_UPDATE_EVIDENCES_REQUESTED(state) {
    state.evidencesState.lifeCycle = 'PENDING';
  },
  BULK_UPDATE_EVIDENCES_SUCCEEDED(state, action) {
    const filteredEvidences = filterEvidences(action.payload, state.practicesFilter, state.yearsFilter);

    state.evidences = filteredEvidences;
    state.allEvidences = action.payload;

    state.evidencesState.lifeCycle = 'SUCCESS';
  },
  BULK_UPDATE_EVIDENCES_ERRORED(state) {
    state.evidencesState.lifeCycle = 'ERROR';
  },
  FILTER_EVIDENCES(state, action) {
    const { practices, years } = action.payload;
    state.practicesFilter = practices;
    state.yearsFilter = years;

    const filteredEvidences = filterEvidences(state.allEvidences, state.practicesFilter, state.yearsFilter);
    state.evidences = filteredEvidences;
  },
});

export default reducer;
