import { Box, Image, Text } from 'grommet';
import cowIcon from '../../../../../../assets/BoundaryTool/cowIcon.svg';
import React, { useState } from 'react';
import { FormDown, FormUp } from 'grommet-icons';
import reducedTillageIcon from '../../../../../../assets/BoundaryTool/practices/reducedTillageIcon.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useMapContext } from '../../../../service/map/MapContext';
import { PracticesIconsComponent } from '../../../Field/PracticesIconsComponent';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

interface FieldDropdownProps {
  field: any;
}

export const FieldDropdown = ({ field }: FieldDropdownProps): JSX.Element => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { setState, paddocks, currentVersion } = useMapContext();
  const { journeyId } = useParams();
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Box style={{ minHeight: 'unset' }}>
      <Box
        data-cy={`field-dropdown-${field.properties.name}`}
        onClick={() => setExpanded(!expanded)}
        style={{
          borderRadius: expanded ? '5px 5px 0 0' : '5px',
          border: '1px solid #CCC',
          padding: '0.5rem 0.75rem',
        }}
      >
        <Box direction={'row'} justify={'between'}>
          <Box
            direction={'row'}
            align={'center'}
            onClick={() => {
              if (!currentVersion) {
                return;
              }
              setState('DETAILS');
              navigate(`/v2/fields/${journeyId}?fieldId=${field.id}`);
            }}
          >
            {field.properties.practiceType === 'PASTURE' ? (
              <Image height={'24px'} width={'24px'} src={cowIcon} />
            ) : (
              <Image height={'24px'} width={'24px'} src={reducedTillageIcon} />
            )}
            <Text margin={'0 0.75rem'} weight={700} size={'1.5rem'}>
              {field.properties.name}
            </Text>
            {currentVersion && (
              <Box pad={'6px 8px'} background={'#FFE137'}>
                <Text weight={700} style={{ boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)' }}>
                  {t('fieldFlow.ranchOverview.fieldDetails.title')}
                </Text>
              </Box>
            )}
          </Box>
          {/*Field History won't be implemented now*/}
          {/*<Box direction={"row"} align={"center"} gap={'0.5rem'}>*/}
          {/*  <Text weight={400} size={'1rem'}>Field History</Text>*/}
          {/*  <Meter size={'80px'} thickness={'8px'} max={100} background={{color: '#FFFEE2'}} color={'#FFE137'} value={40} round={true}/>*/}
          {/*  <Text size={'1.25rem'} weight={900}>40%</Text>*/}
          {/*</Box>*/}
          {expanded ? (
            <Box justify={'center'}>
              <FormUp />
            </Box>
          ) : (
            <Box justify={'center'}>
              <FormDown />
            </Box>
          )}
        </Box>
      </Box>
      {expanded && (
        <Box border={{ color: '#CCC', size: '1px', style: 'solid' }}>
          <Box direction={'row'} gap={'2.5rem'} pad={'0.75rem'} border={{ color: '#CCC', size: '1px', style: 'solid', side: 'bottom' }}>
            <Box>
              <Text weight={700} textAlign={'start'}>
                {t('fieldFlow.units.area')}
              </Text>
              <Text>{field.properties[i18next.language === 'pt-BR' ? 'areaHectare' : 'area'].toFixed(2)}</Text>
            </Box>
            {/*Don't have this information yet*/}
            {/*<Box>*/}
            {/*  <Text weight={700}>Estimated Carbon Potential/Year</Text>*/}
            {/*  <Text>39 Metric Tons of Carbon</Text>*/}
            {/*</Box>*/}
            <Box direction={'row'} align={'center'} gap={'1rem'}>
              <Text weight={900}>{t('fieldFlow.ranchOverview.practices')}</Text>
              <PracticesIconsComponent practices={field.properties?.practices} />
            </Box>
          </Box>
          <Box direction={'row'} gap={'0.5rem'} width={'100%'} pad={'0.75rem'}>
            <Box width={'10%'}>
              <Text weight={900}>N#</Text>
            </Box>
            <Box width={'30%'}>
              <Text weight={900}>{t('fieldFlow.ranchOverview.fieldDetails.paddock')}</Text>
            </Box>
            <Box width={'20%'}>
              <Text weight={900}>{t('fieldFlow.units.area')}</Text>
            </Box>
            {/*Don't have this information yet*/}
            {/*<Box width={'40%'}>*/}
            {/*  <Text weight={700}>Estimated Carbon Potential/Year</Text>*/}
            {/*</Box>*/}
          </Box>
          {paddocks[field.id] && paddocks[field.id].length > 0 ? (
            paddocks[field.id].map((paddock: any, index: number) => {
              return (
                <Box
                  key={paddock.id}
                  direction={'row'}
                  gap={'0.5rem'}
                  width={'100%'}
                  pad={'0.75rem'}
                  border={{ color: '#CCC', size: '1px', style: 'solid', side: 'top' }}
                >
                  <Box width={'11%'}>
                    <Text weight={400}>{index + 1}</Text>
                  </Box>
                  <Box width={'31%'}>
                    <Text weight={400}>{paddock.properties.name}</Text>
                  </Box>
                  <Box width={'20%'}>
                    <Text weight={400}>{paddock.properties[i18next.language === 'pt-BR' ? 'areaHectare' : 'area'].toFixed(2)}</Text>
                  </Box>
                  {/*Don't have this information yet*/}
                  {/*<Box width={'40%'}>*/}
                  {/*  <Text weight={400}>14 Metric Tons Of Carbon</Text>*/}
                  {/*</Box>*/}
                </Box>
              );
            })
          ) : (
            <Box width={'100%'} pad={'0.75rem 0'} border={{ color: '#CCC', size: '1px', style: 'solid', side: 'top' }}>
              <Text textAlign={'center'}>{t('fieldFlow.ranchOverview.noPaddock')}</Text>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default FieldDropdown;
