export const CONTRACT_URL = process.env.REACT_APP_CONTRACT_API_ENDPOINT || process.env.REACT_APP_GATEWAY_API_ENDPOINT;
export const ESTIMATE_URL = process.env.REACT_APP_ESTIMATE_API_ENDPOINT || process.env.REACT_APP_GATEWAY_API_ENDPOINT;
export let BASE_URL = process.env.REACT_APP_BASE_URL || process.env.REACT_APP_GATEWAY_API_ENDPOINT;
export const PDF_URL = process.env.REACT_APP_PDF_API_ENDPOINT || process.env.REACT_APP_GATEWAY_API_ENDPOINT;
export const FIELD_URL = process.env.REACT_APP_FIELD_API_ENDPOINT || process.env.REACT_APP_GATEWAY_API_ENDPOINT;
export const SOIL_SAMPLING_URL = process.env.REACT_APP_SAMPLING_API_ENDPOINT || process.env.REACT_APP_GATEWAY_API_ENDPOINT;
export const ACQUISITION_URL = process.env.REACT_APP_ACQUISITION_API_ENDPOINT || process.env.REACT_APP_GATEWAY_API_ENDPOINT;

export function setLambda (isLambdaBackend) {
    if (isLambdaBackend) {
        BASE_URL = process.env.REACT_APP_LAMBDA_URL
        console.log(BASE_URL)
    } else {
        BASE_URL = process.env.REACT_APP_BASE_URL || process.env.REACT_APP_GATEWAY_API_ENDPOINT;
    }
}
