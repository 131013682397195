import {
  DCEventTypes,
  EventsSuccess,
  EventTypes,
  IDCHarvestEvent,
  IDCPlantingEvent,
  IEvents,
} from '../../pages/customer-profile/components/Tabs/DataCollectionTab/interfaces';
import { createReducer } from '@reduxjs/toolkit';
import { eventsModified } from '../../pages/customer-profile/components/Tabs/DataCollectionTab/utils/utils';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const intialEditedObject = {
  Tillage: { name: 'Tillage', events: {} },
  Planting: { name: 'Planting', events: {} },
  Harvest: { name: 'Harvest', events: {} },
  CoverCrop: { name: 'CoverCrop', events: {} },
  Fertilization: { name: 'Fertilization', events: {} },
  Seeding: { name: 'Seeding', events: {} },
  FieldData: { name: 'FieldData', events: {} },
  AnimalData: { name: 'AnimalData', events: {} },
};

const initialState: any = {
  fieldsFilter: [],
  eventsFilter: [],
  showOnlyErrors: false,
  implementationYear: null,
  implementationYearLoading: false,
  grazingSeason: null,
  stateLocationRelations: [],
  dataCollectionEventsTypesEdited: intialEditedObject,
  dataCollectionEventsTypes: {},
  dataCollectionEventsTypesFull: {}, //Used for filtering and controlling event versions
  modelInputDefaults: [],
  eventsToDelete: [
    { name: 'Tillage', eventIds: [] },
    { name: 'Planting', eventIds: [] },
    { name: 'Harvest', eventIds: [] },
    { name: 'CoverCrop', eventIds: [] },
    { name: 'Fertilization', eventIds: [] },
    { name: 'Seeding', eventIds: [] },
    { name: 'FieldData', eventIds: [] },
    { name: 'AnimalData', eventIds: [] },
  ],
  dataCollectionEventsTypesState: { lifeCycle: 'NONE' },
  eventSave: { lifeCycle: 'NONE' },
  eventDelete: { lifeCycle: 'NONE', inDeletion: [] },
  fieldSectionsOpen: {},
  showDeleteModal: false,
  singleEventDelete: {}, //Delete outside of edit
  eventModified: false, //Whether we should show the update modal
  eventDuplicated: false, //Whether we should show the update modal
};

const reducer = createReducer(initialState, {
  GET_EVENTS_TYPES_REQUESTED(state) {
    state.dataCollectionEventsTypesState.lifeCycle = 'PENDING';
  },
  GET_EVENTS_TYPES_SUCCEEDED(state, action) {
    const { events } = action.payload;
    state.dataCollectionEventsTypesState.lifeCycle = 'SUCCESS';
    state.dataCollectionEventsTypes = events;
    state.dataCollectionEventsTypesFull = events;
  },
  GET_EVENTS_TYPES_ERROR(state, action) {
    state.dataCollectionEventsTypesState = { lifeCycle: 'ERROR', error: action.payload };
    state.dataCollectionEventsTypes = {};
    state.dataCollectionEventsTypesFull = {};
  },
  GET_IMPLEMENTATION_YEAR_REQUESTED(state) {
    state.implementationYearLoading = true;
  },
  GET_IMPLEMENTATION_YEAR_SUCCEEDED(state, action) {
    const { implementationYear } = action.payload;
    state.implementationYear = implementationYear;
    state.implementationYearLoading = false;
  },
  GET_IMPLEMENTATION_YEAR_ERROR(state) {
    state.implementationYear = null;
    state.implementationYearLoading = false;
  },
  GET_FARM_INFORMATION_REQUESTED(state, action) {
    state.implementationYearLoading = true;
  },
  GET_FARM_INFORMATION_SUCCEEDED(state, action) {
    const { implementationYear, grazingSeason } = action.payload;
    state.implementationYear = implementationYear;
    state.implementationYearLoading = false;
    state.grazingSeason = grazingSeason;
  },
  GET_FARM_INFORMATION_ERROR(state) {
    state.implementationYear = null;
    state.grazingSeason = null;
    state.implementationYearLoading = false;
  },
  UPSERT_IMPLEMENTATION_YEAR_REQUESTED(state) {
    state.implementationYearLoading = true;
  },
  UPSERT_IMPLEMENTATION_YEAR_SUCCEEDED(state, action) {
    const { implementationYear } = action.payload;
    state.implementationYear = implementationYear;
    state.implementationYearLoading = false;
  },
  UPSERT_IMPLEMENTATION_YEAR_ERROR(state) {
    state.implementationYearLoading = false;
  },
  UPSERT_GRAZING_SEASON_SUCCEEDED(state, action) {
    const { grazingSeason } = action.payload;
    state.grazingSeason = grazingSeason;
  },
  GET_MODEL_INPUT_DEFAULTS_SUCCEEDED(state, action) {
    const modelInputDefaults = action.payload;
    state.modelInputDefaults = modelInputDefaults;
  },
  GET_STATE_LOCATION_RELATIONS_SUCCEEDED(state, action) {
    state.stateLocationRelations = action.payload;
  },
  CANCEL_EDIT(state) {
    Object.values(state.dataCollectionEventsTypesFull as DCEventTypes).forEach((eventType) => {
      Object.values(state.dataCollectionEventsTypesEdited[eventType.name].events as IEvents[]).forEach((event) => {
        if (event.newEvent) {
          delete state.dataCollectionEventsTypesFull[eventType.name].events[event.id];
        }
      });

      state.dataCollectionEventsTypesEdited[eventType.name].events = {};
    });
    state.eventModified = false;
    state.eventsToDelete = state.eventsToDelete.map((eventType) => {
      return {
        ...eventType,
        eventIds: [],
      };
    });
  },
  ADD_NEW_EVENT(state, action) {
    const { eventType, eventToAdd } = action.payload;
    state.dataCollectionEventsTypesEdited[eventType].events[eventToAdd.id] = eventToAdd;
    state.dataCollectionEventsTypesFull[eventType].events[eventToAdd.id] = eventToAdd;
    state.eventModified = true;
  },
  HARVEST_EVENTS_UPDATED(state, action) {
    const { eventsUpdated } = action.payload;
    eventsUpdated.forEach((hv: IDCHarvestEvent) => {
      state.dataCollectionEventsTypesEdited['Harvest'].events[hv.id] = hv;
    });
    state.eventModified = true;
  },
  UPDATE_EVENT(state, action) {
    const { eventType, event } = action.payload;
    const typeAttributes = state.dataCollectionEventsTypes[eventType].constraints.map((c) => c.attributeName);
    state.eventModified = eventsModified(event, state.dataCollectionEventsTypesFull[eventType].events[event.id], typeAttributes);
    state.dataCollectionEventsTypes[eventType].events[event.id] = event;
    state.fieldSectionsOpen = {
      ...state.fieldSectionsOpen,
      [event.fieldId]: true,
    };
  },
  EVENT_UPDATED(state, action) {
    const { eventType, event } = action.payload;
    const typeAttributes = state.dataCollectionEventsTypes[eventType].constraints.map((c) => c.attributeName);
    state.eventModified = eventsModified(event, state.dataCollectionEventsTypesFull[eventType].events[event.id], typeAttributes);
    state.dataCollectionEventsTypesEdited[eventType].events[event.id] = event;
  },
  UPDATE_PLANTING_EVENT(state, action) {
    const { event, movedHarvestEvents } = action.payload;
    const typeAttributes = state.dataCollectionEventsTypes['Planting'].constraints.map((c) => c.attributeName);
    state.eventModified = eventsModified(event, state.dataCollectionEventsTypesFull['Planting'].events[event.id], typeAttributes);
    state.dataCollectionEventsTypesEdited['Planting'].events[event.id] = event;
    movedHarvestEvents.forEach((he) => {
      state.dataCollectionEventsTypesEdited['Harvest'].events[he.id] = he;
      state.dataCollectionEventsTypesFull['Harvest'].events[he.id] = he;
    });
    state.fieldSectionsOpen = {
      ...state.fieldSectionsOpen,
      [event.fieldId]: true,
    };
  },
  DELETE_SINGLE_EVENT_REQUESTED(state) {
    state.showDeleteModal = false;
    state.eventDelete = {
      lifeCycle: 'PENDING',
      inDeletion: state.eventDelete.inDeletion.concat(state.singleEventDelete.id),
    };
  },
  DELETE_EVENT_SUCCEEDED(state, action) {
    const eventsDeleted = action.payload;
    for (const eventTypeDeletion of eventsDeleted) {
      eventTypeDeletion.eventIds.forEach((id) => {
        delete state.dataCollectionEventsTypes[eventTypeDeletion.name].events[id];
        delete state.dataCollectionEventsTypesFull[eventTypeDeletion.name].events[id];
        if (eventTypeDeletion.name === 'Planting') {
          (Object.values(state.dataCollectionEventsTypes['Harvest'].events) as IDCHarvestEvent[])
            .filter((hv) => hv?.plantingId === id)
            .forEach((hvId) => {
              delete state.dataCollectionEventsTypes['Harvest'].events[hvId.id];
            });
          (Object.values(state.dataCollectionEventsTypesFull['Harvest'].events) as IDCHarvestEvent[])
            .filter((hv) => hv?.plantingId === id)
            .forEach((hvId) => {
              delete state.dataCollectionEventsTypesFull['Harvest'].events[hvId.id];
            });
        }
      });
    }
    //Clear events to delete

    state.eventsToDelete.forEach((eventType) => {
      eventType.eventIds = [];
    });
    state.eventDelete = {
      lifeCycle: 'SUCCESS',
      inDeletion: state.eventDelete.inDeletion.filter((e: string) => e === action.payload.eventId),
    };
  },
  DELETE_EVENT_ERROR(state, action) {
    //Clear events to delete
    state.eventsToDelete.forEach((eventType) => {
      eventType.eventIds = [];
    });
    state.eventDelete = {
      lifeCycle: 'ERROR',
      error: action.payload,
      inDeletion: state.eventDelete.inDeletion.filter((e: string) => e === action.payload.eventId),
    };
  },
  DUPLICATE_EVENT_REQUESTED(state, action) {
    const { eventId, eventType } = action.payload;
    const currentEvent =
      state.dataCollectionEventsTypesEdited[eventType].events[eventId] ?? state.dataCollectionEventsTypes[eventType].events[eventId];
    const duplicatedEvent = { ...currentEvent, id: uuidv4(), newEvent: true };

    state.dataCollectionEventsTypesEdited[eventType].events[duplicatedEvent.id] = duplicatedEvent;
    state.dataCollectionEventsTypesFull[eventType].events[duplicatedEvent.id] = duplicatedEvent;

    state.eventDuplicated = true;
  },
  DUPLICATE_PLANTING_EVENT_REQUESTED(state, action) {
    const { eventId } = action.payload;

    const currentPlanting =
      state.dataCollectionEventsTypesEdited['Planting'].events[eventId] ??
      (state.dataCollectionEventsTypes['Planting'].events[eventId] as IDCPlantingEvent);

    const duplicatedPlantingEvent = { ...currentPlanting, id: uuidv4(), newEvent: true };

    const allHarvestEvents = (Object.values(state.dataCollectionEventsTypesEdited['Harvest'].events) as IDCHarvestEvent[]).some(
      (hv) => hv.plantingId === currentPlanting.id,
    )
      ? state.dataCollectionEventsTypesEdited['Harvest'].events
      : state.dataCollectionEventsTypes['Harvest'].events;

    const duplicatedHarvestEvents: IDCHarvestEvent[] = [];

    (Object.values(allHarvestEvents) as IDCHarvestEvent[]).forEach((he) => {
      if (he.plantingId === currentPlanting.id) {
        duplicatedHarvestEvents.push({ ...he, id: uuidv4(), plantingId: duplicatedPlantingEvent.id, newEvent: true });
      }
    });

    state.dataCollectionEventsTypesEdited['Planting'].events[duplicatedPlantingEvent.id] = duplicatedPlantingEvent;
    state.dataCollectionEventsTypesFull['Planting'].events[duplicatedPlantingEvent.id] = duplicatedPlantingEvent;
    for (const harvestEvent of duplicatedHarvestEvents) {
      state.dataCollectionEventsTypesEdited['Harvest'].events[harvestEvent.id] = harvestEvent;
      state.dataCollectionEventsTypesFull['Harvest'].events[harvestEvent.id] = harvestEvent;
    }

    state.eventDuplicated = true;
  },
  MARK_EVENT_AS_DELETED(state, action) {
    const { eventType, eventId } = action.payload;
    state.eventsToDelete.find((eT) => eventType === eT.name).eventIds.push(eventId);
    if (eventType === 'Planting') {
      const relatedHarvest = (Object.values(state.dataCollectionEventsTypes['Harvest'].events) as IDCHarvestEvent[])
        .concat(Object.values(state.dataCollectionEventsTypesEdited['Harvest'].events) as IDCHarvestEvent[])
        .filter((e) => eventId === e.plantingId)
        .map((e) => e.id);
      state.eventsToDelete.find((eT) => eT.name === 'Harvest').eventIds.push(...relatedHarvest);
    }
  },
  DELETE_MODAL_REQUEST(state, action) {
    state.singleEventDelete = action.payload;
    state.showDeleteModal = true;
  },
  CANCEL_EVENT_DELETE(state) {
    state.singleEventDelete = {};
    state.showDeleteModal = false;
  },
  REMOVE_NEW_EVENT(state, action) {
    const { eventType, eventId } = action.payload;
    delete state.dataCollectionEventsTypesEdited[eventType].events[eventId];
    delete state.dataCollectionEventsTypesFull[eventType].events[eventId];

    if (eventType === 'Planting') {
      (Object.values(state.dataCollectionEventsTypesEdited['Harvest'].events) as IDCHarvestEvent[]).forEach((harvest: IDCHarvestEvent) => {
        if (harvest.plantingId === eventId) {
          delete state.dataCollectionEventsTypesEdited['Harvest'].events[harvest.id];
          delete state.dataCollectionEventsTypesFull['Harvest'].events[harvest.id];
        }
      });
    }
  },
  SAVE_EVENT_REQUESTED(state) {
    state.eventSave.lifeCycle = 'PENDING';
    state.eventModified = false;
    state.eventDuplicated = true;
    state.showDeleteModal = false;
  },
  SAVE_EVENT_SUCCEEDED(state, action) {
    const eventsSaved: EventsSuccess = action.payload.eventsSuccess;
    Object.keys(eventsSaved).forEach((eventType) => {
      Object.values(eventsSaved[eventType] as Record<string, IEvents>).forEach((event) => {
        state.dataCollectionEventsTypes[eventType].events[event.id] = eventsSaved[eventType][event.id];
        state.dataCollectionEventsTypesFull[eventType].events[event.id] = eventsSaved[eventType][event.id];
        delete state.dataCollectionEventsTypesEdited[eventType].events[event.id];
      });
    });
    state.eventSave.lifeCycle = 'SUCCEEDED';
  },
  SAVE_EVENT_ERROR(state) {
    state.eventSave.lifeCycle = 'ERRORED';
  },
  FILTER_EVENTS(state, action) {
    const { fieldIds, years, events, showOnlyErrors } = action.payload;
    state.fieldsFilter = fieldIds;
    state.eventsFilter = events;
    const filteredEvents = filterEvents(state.dataCollectionEventsTypesFull, fieldIds, years, showOnlyErrors);
    state.dataCollectionEventsTypes = filteredEvents;
  },
  BULK_COPY_EVENTS(state, action) {
    state.dataCollectionEventsTypes = action.payload.eventsTypes;
    state.dataCollectionEventsTypesFull = action.payload.eventsTypes;
    state.dataCollectionEventsTypesEdited = intialEditedObject;
  },
  CLOSE_FIELD_SECTION(state, action) {
    const { fieldId } = action.payload;
    state.fieldSectionsOpen = {
      ...state.fieldSectionsOpen,
      [fieldId]: false,
    };

    const eventTypesEditedCopy = cloneDeep(state.dataCollectionEventsTypesEdited);
    for (const eventType of Object.values(eventTypesEditedCopy as DCEventTypes)) {
      for (const updatedEvent of Object.values(eventType.events)) {
        state.dataCollectionEventsTypes[eventType.name].events[updatedEvent.id] = updatedEvent;
        state.dataCollectionEventsTypesFull[eventType.name].events[updatedEvent.id] = updatedEvent;
      }
    }
  },
});

const filterEvents = (eventsTypesFull: DCEventTypes, fieldIds: string[], years: number[], showOnlyErrors: boolean) => {
  const eventCopy = JSON.parse(JSON.stringify(eventsTypesFull)) as DCEventTypes;
  Object.values(eventCopy).forEach((eventType) => {
    Object.values(eventType.events).forEach((event: any) => {
      if (!event.newEvent) {
        //todo find a better way to handle the different types
        const date =
          'tillageDate' in event
            ? event.tillageDate
            : 'plantingDate' in event
            ? event.plantingDate
            : 'harvestDate' in event
            ? event.harvestDate
            : 'dateApplied' in event
            ? event.dateApplied
            : 'terminationDate' in event
            ? event.terminationDate
            : 'dateOfApplication' in event
            ? event.dateOfApplication
            : 'year' in event
            ? event.year
            : null;
        if (
          (fieldIds.length && fieldIds.indexOf(event.fieldId) === -1) ||
          (showOnlyErrors && !event.erroredEvent) ||
          (years.length &&
            (!date ||
              (['Planting', 'Harvest'].includes(eventType.name)
                ? verifyPlantingAndHarvestDates(event, eventType.name, eventsTypesFull, years)
                : years.indexOf(new Date(date).getUTCFullYear()) === -1)))
        ) {
          delete eventCopy[eventType.name].events[event.id];
        }
      }
    });
  });
  return eventCopy;
};

const verifyPlantingAndHarvestDates = (
  event: IDCPlantingEvent | IDCHarvestEvent,
  eventType: EventTypes,
  eventsTypesFull: DCEventTypes,
  years: number[],
) => {
  const relatedYears: Set<number> = new Set();

  const allHarvests = Object.values(eventsTypesFull).find((type) => type.name === 'Harvest')?.events as unknown as IDCHarvestEvent[];
  let harvestsFromPlanting: IDCHarvestEvent[];

  if (eventType === 'Planting') {
    const typedEvent = event as IDCPlantingEvent;
    relatedYears.add(new Date(typedEvent.plantingDate).getUTCFullYear());

    harvestsFromPlanting = Object.values(allHarvests).filter((hv) => hv.plantingId === typedEvent.id);

    if (!!harvestsFromPlanting?.length) {
      harvestsFromPlanting.forEach((hv) => {
        relatedYears.add(new Date(hv.harvestDate).getUTCFullYear());
      });
    }
  } else {
    const typedEvent = event as IDCHarvestEvent;

    const allPlantings = Object.values(eventsTypesFull).find((type) => type.name === 'Planting')?.events as unknown as IDCPlantingEvent[];
    const planting = allPlantings[typedEvent.plantingId];

    harvestsFromPlanting = Object.values(allHarvests).filter((hv) => hv.plantingId === typedEvent.plantingId);

    relatedYears.add(new Date(planting.plantingDate).getUTCFullYear());
  }

  harvestsFromPlanting.forEach((hv) => {
    relatedYears.add(new Date(hv.harvestDate).getUTCFullYear());
  });

  for (const year of years) {
    if (relatedYears.has(year)) {
      return false;
    }
  }

  return true;
};

export default reducer;
