import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './index.css';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { Grommet } from 'grommet';
import { Provider } from 'react-redux';
import store from './redux';
import reportWebVitals from './reportWebVitals';
import { defaultTheme } from './theme';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ToastContainer } from 'react-toastify';
import { AdminPortalLDContextProvider } from './providers/AdminContext';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_RELEASE_VERSION,
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const AppWithLaunchDarkly = () => {
  const { user } = useAuth0();
  const LDProviderWrappedApp = withLDProvider({
    clientSideID: String(process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID),
    context: {
      key: user?.sub || 'anonymous-user',
      email: user?.email,
      name: user?.name,
    },
  })(App);
  return (
    <AdminPortalLDContextProvider user={user}>
      <LDProviderWrappedApp />
    </AdminPortalLDContextProvider>
  );
};

const Auth0ProviderWithRedirectCallback = ({ children }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
  };
  return (
    <Auth0Provider
      cacheLocation='localstorage'
      scope='read:current_user read:current_user_metadata openid email profile'
      audience='https://api.pa.agoro.com'
      domain={process.env.REACT_APP_AUTH0_DOMAIN || 'agoro-stage.us.auth0.com'}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || '29pkXcEeckhNbdGHQz17U5IvBppUo1FU'}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

root.render(
  <React.StrictMode>
    <Grommet theme={defaultTheme}>
      <ToastContainer />
      <Provider store={store}>
        <BrowserRouter>
          <Auth0ProviderWithRedirectCallback>
            <AppWithLaunchDarkly />
          </Auth0ProviderWithRedirectCallback>
        </BrowserRouter>
      </Provider>
    </Grommet>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
