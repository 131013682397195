import { Down } from 'grommet-icons';
import { useMemo, useState } from 'react';
import { Avatar, DropButton, Text, Image } from 'grommet';
import { Box } from 'grommet';
import { useAuth0 } from '@auth0/auth0-react';
import AgoroLogoSmall from '../../assets/agoro-logo-small.svg';
import { HeaderBox } from './custom/customComponents';
import { useNavigate, useLocation } from 'react-router-dom';
import { ContractService } from '../../service/contract/contract.service';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { clearEstimateStore } from '../../redux/actions/estimate-action';
import { clearProducerStore } from '../../redux/actions/producer-action';
import { clearSFFields } from '../../redux/actions/field-action';
import { UserService } from '../../service/common/user.service';
import AgoToast from '../Toast/AgoToast';
import { AUTH0_ROLES } from '../../pages/customer-profile/utils/utils';
import { getDraft } from '../../service/estimate/estimate.service';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ProfileButton {
  dataCy: string;
  key: string;
  label: JSX.Element;
  icon?: JSX.Element;
  onClick?: () => void;
}

export function AgoroHeader() {
  const [profileMenuOpened, setProfileMenuOpened] = useState(false);
  const { logout, loginWithRedirect } = useAuth0();
  const user = useSelector((state: any) => {
    return state.user.userData;
  });
  const region = useSelector((state: any) => state.user.userData?.country);
  const prodEnvironment = process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'prod';
  const userFullName = user?.fullName || user?.email || '';
  const userInitials = ContractService.makeInitials(userFullName);
  const location = useLocation();
  const navigate = useNavigate();
  const { enableEstimationToolAccess, usCometV2, changeUserCountry } = useFlags();
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const isCP: boolean = !!user.channelPartnerId.length;

  function handleCountryChange() {
    UserService.updateUserMetadata({ id: user.id, metadata: { country: region === 'BR' ? 'US' : 'BR' } })
      .then(() => {
        const ui_locales = region === 'BR' ? 'pt-BR' : 'en';
        //Used "loginWithRedirect" to refresh page and get new auth0 info
        loginWithRedirect({
          ui_locales,
          appState: {
            returnTo: window.location.pathname + (window.location.search ? window.location.search : ''),
          },
        });
      })
      .catch(() => {
        AgoToast.showToast({
          title: 'Error',
          message: `Couldn't update your region. Try again later`,
          type: 'error',
          toastId: 'update-user-error',
        });
      });
  }

  const menuItems: ProfileButton[] = useMemo(() => {
    const buttons: ProfileButton[] = [
      {
        dataCy: 'user-info',
        key: '1',
        label: (
          <Box alignSelf='center'>
            <div>{user?.email}</div>
          </Box>
        ),
        icon: (
          <Box>
            <Avatar
              style={{
                margin: 'auto',
                width: '24px',
                height: '24px',
                marginRight: '5px',
              }}
              background='#e9e9eb'
              size={'small'}
              flex={false}
            >
              <span style={{ fontWeight: 'bold', fontSize: '10px' }}>{userInitials}</span>
            </Avatar>
          </Box>
        ),
      },
    ];

    if (changeUserCountry ?? user.roles?.includes(AUTH0_ROLES.DELEGATED_ADMIN_ADMIN)) {
      buttons.push({
        dataCy: 'change-region-button',
        key: '2',
        label: (
          <Box style={{ width: '100%' }} pad={{ left: '29px' }}>
            {`${t('pageHeader.user.changeRegion')} ${region === 'BR' ? 'US' : 'BR'}`}
          </Box>
        ),
        onClick: () => handleCountryChange(),
      });
    }

    buttons.push({
      dataCy: 'user-logout-button',
      key: '3',
      label: (
        <Box style={{ width: '100%' }} pad={{ left: '29px' }}>
          {t('pageHeader.user.logout')}
        </Box>
      ),
      onClick: () => {
        logout({ returnTo: window.location.origin });
      },
    });

    return buttons;
  }, [user, changeUserCountry]);

  return (
    <>
      {!prodEnvironment && (
        <Box
          style={{
            position: 'fixed',
            top: '0px',
            left: '0px',
            zIndex: 999,
          }}
          align={'center'}
          justify={'center'}
          width={'100%'}
          height={'40px'}
          border={{ color: '#D03450', size: '1px' }}
          background={'rgb(248,216,220)'}
        >
          <Text size={'16px'}>
            {t('testEnvironment.warning.you')}
            <Text weight={900} size={'16px'}>
              {t('testEnvironment.warning.environment')}
            </Text>
            {t('testEnvironment.warning.insertedData')}
            <Text weight={900} size={'16px'}>
              {t('testEnvironment.warning.willNot')}
            </Text>{' '}
            {t('testEnvironment.warning.salesforce')}
          </Text>
        </Box>
      )}
      <HeaderBox style={{ marginTop: !prodEnvironment ? '40px' : '0px' }}>
        <Box
          style={{
            display: 'flex',
            width: 'fit-content',
            minHeight: 'unset',
            flexDirection: 'row',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <Image
            fit='contain'
            src={AgoroLogoSmall}
            onClick={() => {
              navigate('');
            }}
            style={{
              marginLeft: '20px',
              height: '16px',
              width: '80px',
              display: 'block',
              overflow: 'visible',
              cursor: 'pointer',
            }}
          />
          <Box
            style={{
              display: 'block',
              marginLeft: '2rem',
            }}
          >
            <a
              href={'/leads'}
              onClick={(event) => {
                event.preventDefault();
                navigate('/leads');
              }}
              style={{
                color: 'rgb(35, 39, 53)',
                fontWeight: 800,
                textDecoration: location.pathname === '/leads' ? 'underline' : 'none',
              }}
            >
              {!isCP ? t('homePage.cards.leadsList.title') : t('homePage.channelPartnerCards.leadsList.title')}
            </a>
            <a
              href={'/producers'}
              onClick={(event) => {
                event.preventDefault();
                navigate('/producers');
              }}
              style={{
                marginLeft: '1rem',
                fontWeight: 800,
                color: 'rgb(35, 39, 53)',
                textDecoration: location.pathname === '/producers' ? 'underline' : 'none',
              }}
            >
              {!isCP ? t('homePage.cards.producers.title') : t('homePage.channelPartnerCards.producers.title')}
            </a>
            {enableEstimationToolAccess && (
              <a
                href={'/estimator'}
                onClick={async (event) => {
                  event.preventDefault();
                  const estimate = await getDraft(true, usCometV2);
                  await Promise.all([dispatch(clearEstimateStore()), dispatch(clearProducerStore()), dispatch(clearSFFields())]).then(() => {
                    navigate(`/estimator?estimateId=${estimate.id}`);
                  });
                }}
                style={{
                  color: 'rgb(35, 39, 53)',
                  marginLeft: '1rem',
                  fontWeight: 800,
                  textDecoration: location.pathname === '/estimator' ? 'underline' : 'none',
                }}
              >
                {!isCP ? t('homePage.cards.standaloneEstimate') : t('homePage.channelPartnerCards.standaloneEstimate')}
              </a>
            )}
          </Box>
        </Box>
        <Box
          direction='row'
          style={{
            alignItems: 'center',
            padding: '0px',
            gap: '16px',
            position: 'absolute',
            width: '50%',
            height: prodEnvironment ? '40px' : '120px',
            right: '24px',
            top: '8px',
          }}
        >
          <Text
            textAlign='end'
            margin={{ left: 'auto' }}
            style={{
              overflowWrap: 'break-word',
              width: '80%',
              height: '16px',
              color: 'text',
            }}
          >{`${t('pageHeader.user.hi')} ${userFullName}`}</Text>
          <Box
            style={{
              flex: 'none',
              order: '1',
              flexGrow: '0',
              width: '20%',
              height: prodEnvironment ? '40px' : '120px',
            }}
          >
            <Avatar
              style={{
                marginTop: prodEnvironment ? '' : '40px',
                position: 'absolute',
                width: '40px',
                height: '40px',
                background: '#E9E9EB',
              }}
            >
              <span style={{ fontWeight: 'bold', cursor: 'default' }}>{userInitials}</span>
            </Avatar>
            <DropButton
              data-cy={'user-drop-down'}
              plain={true}
              onClick={() => setProfileMenuOpened(!profileMenuOpened)}
              style={{
                position: 'absolute',
                background: 'dark',
                height: prodEnvironment ? '40px' : '120px',
                width: 'calc(20% - 40px)',
                right: '0px',
                padding: '0px',
              }}
              dropAlign={{ right: 'right', top: 'bottom' }}
              open={profileMenuOpened}
              onClose={() => setProfileMenuOpened(false)}
              onOpen={() => setProfileMenuOpened(true)}
              icon={<Down color={'text-strong'} size={'small'} />}
              dropContent={
                <Box pad='small' background='background-black' style={{ display: 'flex' }}>
                  {menuItems.map((menuItem, i, arr) => (
                    <div data-cy={menuItem.dataCy} key={menuItem.key}>
                      <div
                        style={{
                          cursor: 'pointer',
                          height: '32px',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                        onClick={menuItem?.onClick}
                      >
                        {menuItem?.icon} {menuItem.label}
                      </div>
                      {arr.length - 1 !== i && (
                        <div style={{ width: '100%' }}>
                          <hr
                            style={{
                              borderColor: 'rgb(233, 233, 235)',
                              borderWidth: '0px 0px 1px',
                              borderStyle: 'solid',
                            }}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </Box>
              }
            />
          </Box>
        </Box>
      </HeaderBox>
    </>
  );
}

export default AgoroHeader;
