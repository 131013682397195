import React, { useEffect, useState } from 'react';
import './App.css';
import './css/buttons.css';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { getAppInitializedAction } from './redux/actions/context';
import RoutingProvider from './providers/RoutingProvider';
import { AgoroHeader } from './components/agoro-header/AgoroHeader.component';
import { useLocation } from 'react-router-dom';
import { Box, Grid } from 'grommet';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { hotjar } from 'react-hotjar';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import { User } from './redux/reducers/user-reducer';
import { setLambda } from './service/common/env';
import { useAdminLD } from './providers/AdminContext';

const App = () => {
  const { pathname } = useLocation();
  const [isBaseLDInitialized, setIsBaseLDInitialized] = useState(false);
  const [isAdminLDInitialized, setIsAdminLDInitialized] = useState(false);
  const dispatch = useDispatch();
  const baseLDClient = useLDClient();
  const { adminPortalLD } = useAdminLD();
  hotjar.initialize(process.env.REACT_APP_HOTJAR_ID ? Number(process.env.REACT_APP_HOTJAR_ID) : 3288859, 6);
  const { user, getAccessTokenSilently, loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    if (isLoading) return;
    if (!isAuthenticated) {
      const ui_locales = navigator.language === 'pt-BR' ? 'pt-BR' : 'en';
      loginWithRedirect({
        ui_locales,
        appState: {
          returnTo: window.location.pathname + (window.location.search ? window.location.search : ''),
        },
      });
      return;
    }
    dispatch(getAppInitializedAction(getAccessTokenSilently, user));
  }, [user, dispatch, getAccessTokenSilently, loginWithRedirect, isAuthenticated, isLoading]);

  const isInitialized = useSelector((state: any) => {
    return state.context.isInitialized;
  });
  const userData: User = useSelector((state: any) => {
    return state.user.userData;
  });
  const userLanguage = userData?.language;

  const removeLoaderContainer = () => {
    document.getElementById('loader-container')?.remove();
  };

  if (userLanguage) {
    if (!i18n.language || i18n.language !== userLanguage) {
      i18n.changeLanguage(userLanguage).then(() => {
        setTimeout(() => {
          removeLoaderContainer();
        }, 700);
      });
    } else {
      removeLoaderContainer();
    }
  }

  useEffect(() => {
    if (baseLDClient && adminPortalLD && userData) {
      const ldUserData = {
        kind: 'user',
        key: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        name: userData.fullName,
        email: userData.email,
        country: userData.country,
        roles: userData.roles,
        partnerTier: userData.partnerTier,
      };

      adminPortalLD.identify(ldUserData).then((_) => {
        setIsAdminLDInitialized(true);
      });
      baseLDClient.identify(ldUserData).then((_) => {
        setIsBaseLDInitialized(true);
      });
    }
    if (hotjar.initialized() && userData) {
      hotjar.identify(userData.id, { userProperty: { roles: userData.roles } });
    }
  }, [baseLDClient, adminPortalLD, userData]);

  useEffect(() => {
    if (hotjar.initialized()) {
      hotjar.stateChange(pathname);
    }
  }, [pathname]);

  const { useLambdaBackend } = useFlags();

  useEffect(() => {
    setLambda(useLambdaBackend);
  }, [useLambdaBackend]);

  return isBaseLDInitialized && isAdminLDInitialized && isInitialized && userData ? (
    <Grid
      height={{ min: '100vh' }}
      className='App'
      fill
      rows={['auto', 'flex']}
      columns={['auto', 'flex']}
      areas={[
        { name: 'header', start: [0, 0], end: [1, 0] },
        { name: 'main', start: [0, 1], end: [1, 1] },
      ]}
    >
      <Box gridArea='header' align='center' justify='center'>
        <AgoroHeader />
      </Box>
      <Box gridArea='main'>
        <I18nextProvider i18n={i18n}>
          <RoutingProvider />
        </I18nextProvider>
      </Box>
    </Grid>
  ) : null;
};

export default App;
