import { Box, Layer, Spinner, Text } from 'grommet';
import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
// @ts-ignore
import { IframeMessageProxy } from 'iframe-message-proxy';
import { useTranslation } from 'react-i18next';
import AgoToast from '../components/Toast/AgoToast';

//components
import { MapPage } from '../pages/map/Map.page';
// @ts-ignore
import { MapV2Page } from '../pages/map/Map.v2.page';
// lazy loading
const UsersPage = React.lazy(() => import('../pages/admin/Users.page'));
const ApiDocsPage = React.lazy(() => import('../pages/docs/ApiDocs.page'));
const EstimationPage = React.lazy(() => import('../pages/estimation-tool/Estimation.page'));
const CustomerProfile = React.lazy(() => import('../pages/customer-profile/lib/CustomerProfile'));
const Growers = React.lazy(() => import('../pages/grower-list/GrowerList.page'));
const Home = React.lazy(() => import('../pages/home/Home.page'));
const Leads = React.lazy(() => import('../pages/lead-list/LeadList.page'));
const ProducerList = React.lazy(() => import('../pages/producer-list/ProducerList.page'));
const ContractGeneration = React.lazy(() => import('../pages/contract-creation/ContractGeneration.page'));

const RoutingProvider = (): JSX.Element => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [mapLoaded, setMapLoaded] = useState(false);
  IframeMessageProxy.listen();
  window.addEventListener('message', (rawMessage) => {
    const message = rawMessage.data.message;
    if (message?.action?.startsWith('mapEvent:')) {
      if (message.content === 'PAGE-READY') {
        setMapLoaded(true);
      }
      if (message.action.endsWith('navigate')) {
        navigate(message.content);
      }
      if (message.action.endsWith('showToast')) {
        AgoToast.showToast(message.content);
      }
    }
  });
  // When navigating between pages, we expect the scrollbar to go to the top, instead of staying where it was on the old page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getFallback = (component) => {
    return (
      <Suspense
        fallback={
          <Box direction='row' gap='medium' style={{ margin: '1rem auto' }}>
            <Spinner size='medium' color={'#FFE137'} />
          </Box>
        }
      >
        {component}
      </Suspense>
    );
  };

  return (
    <Routes>
      <Route path='/' element={getFallback(<Home />)} />
      <Route path='/growers' element={getFallback(<Growers />)} />
      <Route path='/leads' element={getFallback(<Leads />)} />
      <Route path='/producers' element={getFallback(<ProducerList />)} />
      <Route path='/admin/users' element={getFallback(<UsersPage />)} />
      <Route path='/admin/api-docs' element={getFallback(<ApiDocsPage />)} />
      <Route path='/contract-generation' element={getFallback(<ContractGeneration />)} />
      <Route path='/estimator' element={getFallback(<EstimationPage />)} />
      <Route path='/estimator/:journeyId' element={getFallback(<EstimationPage />)} />
      <Route path='/leads/:journeyId' element={getFallback(<CustomerProfile />)} />
      <Route
        path='/fields/:salesforceId/:mapType'
        element={
          <>
            {!mapLoaded && (
              <Layer>
                <Box direction={'row'}>
                  <Spinner pad={'24px'} margin={'24px'} />
                  <Text size={'20px'} weight={800} alignSelf={'center'} margin={{ right: '24px' }}>
                    {t('loadingMap')}
                  </Text>
                </Box>
              </Layer>
            )}
            <MapPage />
          </>
        }
      />
      <Route path='/v2/fields/:journeyId' element={getFallback(<MapV2Page />)} />
    </Routes>
  );
};
export default React.memo(RoutingProvider);
