import { useMapContext } from '../../service/map/MapContext';
import { useSearchParams } from 'react-router-dom';
import { MapUtilities } from '../../service/map/MapUtilities';
import { FieldColorTokens } from './tokens/field.color.tokens';
import { useFlags } from 'launchdarkly-react-client-sdk';

const setSourceData = (map, sourceId, features) => {
  if (!map) {
    return;
  }
  const geojson = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: features,
    },
  };
  map.getSource(sourceId).setData(geojson.data);
};

const verifyIntersectionsNearFields = (nearFields, field) => {
  let intersects = false;
  for (const nearField of nearFields) {
    try {
      if (!nearField.unenrolledAt && !field.properties.unenrolledAt && MapUtilities.findFeaturesIntersection(nearField.boundaries, field)) {
        if (nearField.type !== 'INELIGIBLE_AREA') {
          intersects = true;
        }
      }
    } catch (e) {
      console.log(e, `Could not find intersections for the field:`, field.name);
    }
  }
  if (intersects) {
    field.properties.intersects = intersects;
  }
};

function AgoroGeoJSONList(): JSX.Element {
  const { map, fields, nearFields, paddocks } = useMapContext();
  const [searchParams] = useSearchParams();
  const fieldId = searchParams.get('fieldId');
  const { hideGrazing, enableAsyncFieldProcessing } = useFlags();

  const geoJSONFeatures: Array<JSX.Element> = [];

  const fieldList: any = [];
  const paddockList: any = [];
  const nearFieldsList: any = [];
  const wetlandsList: any = [];
  const publicLandsList: any = [];

  function addFieldPaddocks(fieldId: string | number, colors) {
    paddocks[fieldId].forEach((paddock) => {
      if (!paddock?.properties) return;
      paddock.properties.fillColor = colors.fillColor;
      paddock.properties.fillOpacity = colors.fillOpacity;
      paddock.properties.lineColor = colors.lineColor;
      paddock.properties.lineWidth = colors.lineWidth;

      paddockList.push(paddock);
    });
  }

  if (fields && fields.length > 0) {
    fields.forEach((field) => {
      if (!field?.properties) return;
      if (fieldId && field.id !== fieldId) return;
      if (field.properties!.hidden) return;
      if (!field.properties['editing']) {
        verifyIntersectionsNearFields(nearFields, field);

        let colors: any = FieldColorTokens.fieldUnselected;
        if (field.properties.unenrolledAt) colors = FieldColorTokens.fieldUnenrolled;
        if (field.properties.selected) colors = FieldColorTokens.fieldSelected;
        if (field.properties.intersects) colors = FieldColorTokens.fieldIntersection;
        if (!hideGrazing && field.id && paddocks?.[field.id]?.length > 1) {
          addFieldPaddocks(field.id, structuredClone(colors));
          colors = {
            ...colors,
            lineWidth: 0,
            fillOpacity: 0,
          };
        }

        field.properties = {
          ...field.properties,
          ...colors,
        };

        fieldList.push(field);

        if (enableAsyncFieldProcessing && !field.properties?.ineligibleAreasFinalized) {
          const processedProperties = field.properties?.processedProperties ?? null;
          if (processedProperties?.wetlands?.area > 0) {
            for (const wetland of processedProperties.wetlands.boundaries) {
              const wl = {
                id: `${field.id}_${wetland.id}`,
                geometry: wetland.intersection,
                subType: wetland.sub_type,
                type: wetland.type,
                properties: {
                  name: wetland.sub_type,
                  lineColor: FieldColorTokens.wetland.lineColor,
                  lineWidth: FieldColorTokens.wetland.lineWidth,
                  fillColor: FieldColorTokens.wetland.fillColor,
                  fillOpacity: FieldColorTokens.wetland.fillOpacity,
                },
              };
              wetlandsList.push(wl);
            }
          }
          if (processedProperties?.publicLands?.area > 0) {
            for (const publicLand of processedProperties.publicLands.boundaries) {
              const pl = {
                id: `${field.id}_${publicLand.id}`,
                geometry: publicLand.intersection,
                subType: publicLand.sub_type,
                type: publicLand.type,
                properties: {
                  name: 'Public Land',
                  lineColor: FieldColorTokens.publicLand.lineColor,
                  lineWidth: FieldColorTokens.publicLand.lineWidth,
                  fillColor: FieldColorTokens.publicLand.fillColor,
                  fillOpacity: FieldColorTokens.publicLand.fillOpacity,
                },
              };
              publicLandsList.push(pl);
            }
          }
        }
      }
    });
  }

  if (nearFields?.length) {
    for (const field of nearFields) {
      if (field.type === 'INELIGIBLE_AREA') {
        field.boundaries.properties.lineColor = FieldColorTokens.wetland.lineColor;
        field.boundaries.properties.lineWidth = FieldColorTokens.wetland.lineWidth;
        field.boundaries.properties.fillColor = FieldColorTokens.wetland.fillColor;
        field.boundaries.properties.fillOpacity = FieldColorTokens.wetland.fillOpacity;
        if (!wetlandsList.find((i) => i.id === field.id)) {
          wetlandsList.push(field.boundaries);
        }
      } else {
        field.boundaries.properties.lineColor = !field.unenrolledAt
          ? FieldColorTokens.nearField.lineColor
          : FieldColorTokens.fieldUnenrolled.lineColor;
        field.boundaries.properties.lineWidth = FieldColorTokens.nearField.lineWidth;
        field.boundaries.properties.fillColor = !field.unenrolledAt
          ? FieldColorTokens.nearField.fillColor
          : FieldColorTokens.fieldUnenrolled.fillColor;
        field.boundaries.properties.fillOpacity = FieldColorTokens.nearField.fillOpacity;
        if (!nearFieldsList.find((i) => i.id === field.id)) {
          nearFieldsList.push(field.boundaries);
        }
      }
    }
  }

  setSourceData(map, 'wetlands', wetlandsList);
  setSourceData(map, 'public-lands', publicLandsList);
  setSourceData(map, 'near-fields', nearFieldsList);
  setSourceData(map, 'fields', fieldList);
  if (!hideGrazing) setSourceData(map, 'paddocks', paddockList);

  return <div>{geoJSONFeatures}</div>;
}

export default AgoroGeoJSONList;
